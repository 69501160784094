import { MdOutlineDescription } from "react-icons/md";
import { BiBrain } from "react-icons/bi";
import { FcGoogle } from "react-icons/fc";
import { BiCube } from "react-icons/bi";
import type { ListNodeTypes } from "./type";
import { FaFilePdf } from "react-icons/fa";

export const availableNodeTypes: ListNodeTypes[] = [
  {
    id: 0,
    icon: MdOutlineDescription,
    type: "DocumentTools",
    name: "Document Tools",
    isDisabled: false,
    info: "Tool for processing documents",
  },
  {
    id: 1,
    icon: BiBrain,
    type: "LLMTools",
    name: "LLM Tools",
    isDisabled: false,
    info: "Tool for interacting with LLM",
  },
  {
    id: 2,
    icon: FcGoogle,
    type: "GoogleTools",
    name: "Google Tools",
    isDisabled: false,
    info: "Tool for interacting with Google",
  },
  {
    id: 3,
    icon: BiCube,
    type: "BaseTools",
    name: "Basic Tools",
    isDisabled: false,
    info: "Core functionality tool",
  },
  {
    id: 4,
    icon: FaFilePdf,
    type: "DocumentReaders",
    name: "Document Readers",
    isDisabled: false,
    info: "Tool for reading documents",
  },
];
