import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  HStack,
  VStack,
  Text,
  useToast,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import { FiUpload, FiUsers } from "react-icons/fi";
import { createCustomers, getCustomers, uploadCustomerFile } from "../../api/customers";
import { getMemories, editMemory, deleteMemory } from "../../api/memories";
import Papa from "papaparse";
import { validateCSVData, CSVRow } from "../../utils/csvValidation";
import { Customer } from "../../types/customer";
import { Memory } from "../../types/memory";
import { ContactsTable } from "../../components/Contacts/ContactsTable";
import { UploadModal } from "../../components/Contacts/UploadModal";
import { MemoryModal } from "../../components/Contacts/MemoryModal";

const Contacts: React.FC = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [customerMemories, setCustomerMemories] = useState<Memory[]>([]);
  const [isMemoryModalOpen, setIsMemoryModalOpen] = useState(false);
  const [isLoadingMemories, setIsLoadingMemories] = useState(false);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      setIsLoading(true);
      const data = await getCustomers();
      setCustomers(data);
    } catch (error) {
      toast({
        title: "Error fetching customers",
        description:
          error instanceof Error ? error.message : "An error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // Parse and validate CSV
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const validation = validateCSVData(
            results as Papa.ParseResult<CSVRow>
          );

          if (!validation.isValid) {
            setSelectedFile(null);
            toast({
              title: "Invalid CSV File",
              description: validation.error,
              status: "error",
              duration: 8000,
              isClosable: true,
            });
          } else {
            setSelectedFile(file);
            toast({
              title: "File Validated Successfully",
              description: `CSV file contains ${results.data.length} valid records.`,
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          }
        },
        error: (error) => {
          setSelectedFile(null);
          toast({
            title: "Error Reading CSV File",
            description: "Please ensure your file is a valid CSV file.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        },
      });
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    setIsUploading(true);
    try {
      const result = await uploadCustomerFile(selectedFile);
      toast({
        title: "Upload Successful",
        description: "Contacts data has been uploaded successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      await createCustomers({ fileUrl: result.fileUrl });

      await uploadCustomerFile(selectedFile);
      toast({
        title: "Contacts Created",
        description: "Customer data has been created successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      fetchCustomers(); // Refresh the customer list
      onClose();
      setSelectedFile(null);
    } catch (error) {
      toast({
        title: "Upload Failed",
        description:
          error instanceof Error ? error.message : "An error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsUploading(false);
    }
  };

  const formatDate = (timestamp: string) => {
    return new Date(parseInt(timestamp) * 1000).toLocaleString();
  };

  const handleViewMemories = async (customer: Customer) => {
    try {
      setIsLoadingMemories(true);
      const memories = await getMemories(customer.phone_number);
      setCustomerMemories(memories);
      setSelectedCustomer(customer);
      setIsMemoryModalOpen(true);
    } catch (error) {
      toast({
        title: "Error fetching memories",
        description: error instanceof Error ? error.message : "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoadingMemories(false);
    }
  };

  const handleEditMemory = async (memoryId: string, message: string) => {
    try {
      await editMemory(memoryId, { message });
      if (selectedCustomer) {
        const memories = await getMemories(selectedCustomer.phone_number);
        setCustomerMemories(memories);
      }
    } catch (error) {
      toast({
        title: "Failed to update memory",
        description: error instanceof Error ? error.message : "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteMemory = async (memoryId: string) => {
    try {
      await deleteMemory(memoryId);
      if (selectedCustomer) {
        const memories = await getMemories(selectedCustomer.phone_number);
        setCustomerMemories(memories);
      }
    } catch (error) {
      toast({
        title: "Failed to delete memory",
        description: error instanceof Error ? error.message : "An error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="container.xl" px={4}>
      <Box height={"100%"}>
        <VStack align="stretch" spacing={6}>
          {/* Header Section */}
          <HStack justify="space-between" align="center" py={4}>
            <Box>
              <Text as="h1" fontSize="2xl" fontWeight="bold">
                Contacts
              </Text>
              <Text color="gray.600">
                Upload and manage your contact list. You can upload contacts via CSV file.
              </Text>
            </Box>
            <Button
              leftIcon={<Icon as={FiUpload} />}
              colorScheme="teal"
              variant="solid"
              onClick={onOpen}
              flexShrink={0}
            >
              Upload Contacts
            </Button>
          </HStack>

          {/* Stats Box */}
          <Box
            bg="white"
            borderRadius="xl"
            overflow="hidden"
            border="1px solid"
            borderColor="gray.200"
            transition="all 0.2s"
            p={5}
          >
            <VStack align="start" spacing={2}>
              <HStack spacing={2}>
                <Icon as={FiUsers} color="teal.500" boxSize={5} />
                <Text fontSize="lg" fontWeight="medium">
                  Total Contacts
                </Text>
              </HStack>
              <Text fontSize="2xl" fontWeight="bold">
                {customers.length}
              </Text>
            </VStack>
          </Box>

          {/* Contacts Table */}
          <Box
            bg="white"
            borderRadius="xl"
            overflow="hidden"
            border="1px solid"
            borderColor="gray.200"
          >
            <ContactsTable
              customers={customers}
              isLoading={isLoading}
              onViewMemories={handleViewMemories}
              formatDate={formatDate}
              isLoadingMemories={isLoadingMemories}
              isSelectedCustomer={selectedCustomer}
            />
          </Box>
        </VStack>
      </Box>

      <UploadModal
        isOpen={isOpen}
        onClose={onClose}
        selectedFile={selectedFile}
        isUploading={isUploading}
        onFileSelect={handleFileSelect}
        onUpload={handleUpload}
      />

      {selectedCustomer && (
        <MemoryModal
          isOpen={isMemoryModalOpen}
          onClose={() => setIsMemoryModalOpen(false)}
          memories={customerMemories}
          onEditMemory={handleEditMemory}
          onDeleteMemory={handleDeleteMemory}
          customerName={selectedCustomer.customer_name}
          isLoading={isLoadingMemories}
        />
      )}
    </Container>
  );
};

export default Contacts;
