import { useState, useEffect, useRef } from "react";
import { getDocumentUploadStatus } from "../../../api/workflows";

export const useDocumentProcessing = (
  organisationId: string,
  s3Url: string | null
) => {
  const [status, setStatus] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const pollInterval = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!s3Url) return;

    const checkStatus = async () => {
      try {
        const result = await getDocumentUploadStatus(organisationId, s3Url);
        setStatus(result.status);

        if (result.status === "completed") {
          if (pollInterval.current) {
            clearInterval(pollInterval.current);
          }
        } else if (result.status !== "in_progress") {
          setError(new Error("PDF processing failed"));
          if (pollInterval.current) {
            clearInterval(pollInterval.current);
          }
        }
      } catch (err) {
        setError(err as Error);
        if (pollInterval.current) {
          clearInterval(pollInterval.current);
        }
      }
    };

    // Start polling
    checkStatus();
    pollInterval.current = setInterval(checkStatus, 5000);

    // Cleanup
    return () => {
      if (pollInterval.current) {
        clearInterval(pollInterval.current);
      }
    };
  }, [organisationId, s3Url]);

  return { status, error };
};