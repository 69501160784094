import React from "react";
import {
  Box,
  VStack,
  Icon,
  Text,
  Flex,
  Divider,
  Tooltip,
} from "@chakra-ui/react";
import {
  FiHome,
  FiBook,
  FiTarget,
  FiUsers,
  FiBarChart2,
  FiPhoneCall,
  FiMessageSquare,
  FiSettings,
  FiTrello,
  FiBriefcase,
} from "react-icons/fi";
import { NavLink, useLocation } from "react-router-dom";
import { useSidebar } from "../../contexts/SidebarContext";

const SideNavLogo: React.FC<{ isExpanded: boolean }> = ({ isExpanded }) => (
  <Box width={isExpanded ? "180px" : "32px"}>
    <svg width="180" height="45" viewBox="0 0 200 50">
      <g transform="translate(0, 3)">
        <path
          d="M10 15 H40 A5 5 0 0 1 45 20 V30 A5 5 0 0 1 40 35 H25 L15 42 V35 H10 A5 5 0 0 1 5 30 V20 A5 5 0 0 1 10 15 Z"
          fill="#319795"
        />
        <circle cx="20" cy="23" r="2" fill="#E2E8F0" />
        <circle cx="30" cy="23" r="2" fill="#E2E8F0" />
        <circle cx="25" cy="28" r="2" fill="#E2E8F0" />
        <line
          x1="20"
          y1="23"
          x2="25"
          y2="28"
          stroke="#E2E8F0"
          strokeWidth="1"
        />
        <line
          x1="25"
          y1="28"
          x2="30"
          y2="23"
          stroke="#E2E8F0"
          strokeWidth="1"
        />
      </g>

      {isExpanded && (
        <g transform="translate(60, 28)">
          <text
            fontFamily="Poppins, sans-serif"
            fontSize="20"
            fill="#319795"
            fontWeight="600"
            dominantBaseline="middle"
          >
            Agnostech
          </text>
        </g>
      )}
    </svg>
  </Box>
);

const SideNav: React.FC = () => {
  const location = useLocation();
  const { isExpanded, setIsExpanded } = useSidebar();

  return (
    <Box
      width={isExpanded ? "240px" : "60px"}
      bg="white"
      boxShadow="md"
      height="100%"
      display="flex"
      flexDirection="column"
      transition="all 0.3s ease"
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
      overflow="hidden"
    >
      <Flex px={2} py={3} align="center" mb={2}>
        <SideNavLogo isExpanded={isExpanded} />
      </Flex>

      <Divider my={2} borderColor="gray.300" />

      <VStack spacing={4} align="stretch" flex={1}>
        <NavSection title="Company" isExpanded={isExpanded}>
          <NavLink to="/dashboard">
            {() => (
              <NavItem
                icon={FiHome}
                label="Dashboard"
                isActive={location.pathname === "/dashboard"}
                isExpanded={isExpanded}
              />
            )}
          </NavLink>

          <NavLink to="/dashboard/campaigns">
            {() => (
              <NavItem
                icon={FiTarget}
                label="Campaigns"
                isActive={location.pathname.startsWith("/dashboard/campaigns")}
                isExpanded={isExpanded}
              />
            )}
          </NavLink>

          <NavLink to="/dashboard/contacts">
            {() => (
              <NavItem
                icon={FiPhoneCall}
                label="Contacts"
                isActive={location.pathname.startsWith("/dashboard/contacts")}
                isExpanded={isExpanded}
              />
            )}
          </NavLink>

          <NavLink to="/dashboard/analytics">
            {() => (
              <NavItem
                icon={FiBarChart2}
                label="Analytics"
                isActive={location.pathname.startsWith("/dashboard/analytics")}
                isExpanded={isExpanded}
              />
            )}
          </NavLink>
        </NavSection>

        <Divider my={2} borderColor="gray.300" />

        <NavSection title="Agents" isExpanded={isExpanded}>
          <NavLink to="/dashboard/agents">
            {() => (
              <NavItem
                icon={FiUsers}
                label="Pathways"
                isActive={
                  location.pathname.startsWith("/dashboard/agents") ||
                  location.pathname.startsWith("/dashboard/agent")
                }
                isExpanded={isExpanded}
              />
            )}
          </NavLink>

          <NavLink to="/dashboard/knowledge-base">
            {() => (
              <NavItem
                icon={FiBook}
                label="Knowledge Bases"
                isActive={location.pathname.startsWith(
                  "/dashboard/knowledge-base"
                )}
                isExpanded={isExpanded}
              />
            )}
          </NavLink>

          <NavLink to="/dashboard/voices">
            {() => (
              <NavItem
                icon={FiTrello}
                label="Voices"
                isActive={
                  location.pathname.startsWith("/dashboard/voices") ||
                  location.pathname.startsWith("/dashboard/voice")
                }
                isExpanded={isExpanded}
              />
            )}
          </NavLink>

          <NavLink to="/dashboard/workflows">
            {() => (
              <NavItem
                icon={FiBriefcase}
                label="Workflows"
                isActive={
                  location.pathname.startsWith("/dashboard/workflows") ||
                  location.pathname.startsWith("/dashboard/workflow")
                }
                isExpanded={isExpanded}
              />
            )}
          </NavLink>
        </NavSection>

        <Divider my={2} borderColor="gray.300" />

        <NavSection title="Enterprise Chat" isExpanded={isExpanded}>
          <NavLink to="/dashboard/chats">
            {() => (
              <NavItem
                icon={FiMessageSquare}
                label="Chats"
                isActive={location.pathname.startsWith("/dashboard/chats")}
                isExpanded={isExpanded}
              />
            )}
          </NavLink>
        </NavSection>
      </VStack>

      <Divider my={2} borderColor="gray.300" />

      <Box mb={6}>
        <NavLink to={"/dashboard/settings"}>
          {({ isActive }) => (
            <NavItem
              icon={FiSettings}
              label="Settings"
              isActive={isActive}
              isExpanded={isExpanded}
            />
          )}
        </NavLink>
      </Box>
    </Box>
  );
};

const NavSection: React.FC<{
  title: string;
  children: React.ReactNode;
  isExpanded: boolean;
}> = ({ title, children, isExpanded }) => (
  <Box>
    <Box h="24px" px={6} mb={2}>
      <Text
        fontSize="sm"
        fontWeight="bold"
        color="gray.500"
        opacity={isExpanded ? 1 : 0}
        transition="opacity 0.3s ease"
        whiteSpace="nowrap"
      >
        {title}
      </Text>
    </Box>
    <VStack spacing={1} align="stretch">
      {children}
    </VStack>
  </Box>
);

const NavItem: React.FC<{
  icon: any;
  label: string;
  isActive: boolean;
  isExpanded: boolean;
}> = ({ icon, label, isActive, isExpanded }) => (
  <Tooltip
    label={label}
    placement="right"
    hasArrow
    isDisabled={isExpanded}
    openDelay={300}
  >
    <Flex
      align="center"
      px={4}
      py={2}
      h="36px"
      cursor="pointer"
      bg={isActive ? "teal.50" : "transparent"}
      color={isActive ? "teal.500" : "inherit"}
      _hover={{ bg: isActive ? "teal.50" : "gray.100" }}
      transition="background 0.3s ease"
    >
      <Box minW="20px" display="flex" alignItems="center">
        <Icon as={icon} boxSize={5} />
      </Box>
      <Text
        fontSize="sm"
        ml={4}
        transition="opacity 0.3s ease"
        opacity={isExpanded ? 1 : 0}
        whiteSpace="nowrap"
      >
        {label}
      </Text>
    </Flex>
  </Tooltip>
);

export default SideNav;
