import React, { useState } from "react";
import {
  Box,
  Text,
  HStack,
  VStack,
  Button,
  IconButton,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  Container,
  SimpleGrid,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  getKnowledgeBaseMetadataList,
  KnowledgeBaseItem,
  deleteKnowledgeBase,
} from "../../api/knowledgebase";

const KnowledgeBase: React.FC = () => {
  const navigate = useNavigate();
  const [knowledgeBases, setKnowledgeBases] = useState<KnowledgeBaseItem[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchKnowledgeBases();
  }, []);

  const fetchKnowledgeBases = async () => {
    try {
      const response = await getKnowledgeBaseMetadataList("1");
      const formattedKnowledgeBases = response.map(
        (kb: any, index: number) => ({
          knowledgeBaseId: kb.knowledgeBaseId,
          name: kb.name || "Untitled",
          description: kb.description || "No description available",
          updatedAt: kb.updatedAt || null,
          uploadStatus: kb.uploadStatus || "completed",
          knowledgeBaseType: kb.knowledgeBaseType || "",
        })
      );
      setKnowledgeBases(formattedKnowledgeBases);
    } catch (error) {
      console.error("Error fetching knowledge bases:", error);
    }
  };

  const handleCreateKnowledgeBase = () => {
    navigate("/dashboard/knowledge-base/create");
  };

  const handleEditKnowledgeBase = (knowledgeBaseId: string) => {
    navigate(`/dashboard/knowledge-base/${knowledgeBaseId}/edit`);
  };

  const handleDeleteKnowledgeBase = (knowledgeBaseId: string) => {
    if (
      window.confirm("Are you sure you want to delete this knowledge base?")
    ) {
      deleteKnowledgeBase("1", knowledgeBaseId)
        .then(() => {
          fetchKnowledgeBases(); // Refresh the list after deletion
        })
        .catch((error) => {
          console.error("Error deleting knowledge base:", error);
        });
    }
  };

  const filteredKnowledgeBases = knowledgeBases.filter((kb) =>
    kb.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container maxW="container.xl" px={4}>
      <Box height={"100%"}>
        <VStack align="stretch" spacing={6}>
          {/* Header */}
          <Text as="h1" fontSize="2xl" fontWeight="bold" py={4}>
            Knowledge Base
          </Text>

          {/* Search and New KB Section */}
          <HStack spacing={4}>
            <InputGroup maxW="md">
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.400" />
              </InputLeftElement>
              <Input
                bg="white"
                placeholder="Search knowledge bases..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                borderRadius="lg"
                _hover={{ borderColor: "teal.300" }}
                _focus={{ borderColor: "teal.500", boxShadow: "outline" }}
              />
            </InputGroup>

            <Divider orientation="vertical" height="40px" />

            <Button
              colorScheme="teal"
              variant="solid"
              onClick={handleCreateKnowledgeBase}
              flexShrink={0}
            >
              + New Knowledge Base
            </Button>
          </HStack>

          {/* Knowledge Base Grid */}
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            {filteredKnowledgeBases.map((knowledgeBase) => (
              <Box
                key={knowledgeBase.knowledgeBaseId}
                bg="white"
                borderRadius="xl"
                overflow="hidden"
                border="1px solid"
                borderColor="gray.200"
                _hover={{ boxShadow: "md" }}
                transition="all 0.2s"
                opacity={knowledgeBase.uploadStatus === "in_progress" ? 0.7 : 1}
              >
                <VStack align="stretch" h="full">
                  <Box p={5} bg="gray.50">
                    <VStack align="start" spacing={2}>
                      <HStack justify="space-between" width="100%">
                        <Text fontSize="lg" fontWeight="semibold" noOfLines={1}>
                          {knowledgeBase.name}
                        </Text>
                        <HStack spacing={2}>
                          {knowledgeBase.uploadStatus === "in_progress" && (
                            <Box
                              bg="orange.500"
                              color="white"
                              fontSize="xs"
                              px={2}
                              py={1}
                              borderRadius="lg"
                              fontWeight="medium"
                            >
                              Processing...
                            </Box>
                          )}
                          <Box
                            bg="teal.500"
                            color="white"
                            fontSize="xs"
                            px={2}
                            py={1}
                            borderRadius="lg"
                            fontWeight="medium"
                            textTransform="capitalize"
                          >
                            {knowledgeBase.knowledgeBaseType}
                          </Box>
                        </HStack>
                      </HStack>
                      <Text fontSize="sm" color="gray.600" noOfLines={2}>
                        {knowledgeBase.description}
                      </Text>
                    </VStack>
                  </Box>

                  <Box
                    mt="auto"
                    p={4}
                    borderTop="1px solid"
                    borderColor="gray.100"
                  >
                    <HStack justify="space-between">
                      <Text fontSize="sm" color="gray.600">
                        {knowledgeBase.updatedAt || "No date available"}
                      </Text>
                      <HStack spacing={2}>
                        <IconButton
                          size="sm"
                          variant="ghost"
                          colorScheme="teal"
                          icon={<EditIcon />}
                          aria-label="Edit Knowledge Base"
                          onClick={() => handleEditKnowledgeBase(knowledgeBase.knowledgeBaseId)}
                          isDisabled={knowledgeBase.uploadStatus === "in_progress"}
                        />
                        <IconButton
                          size="sm"
                          variant="ghost"
                          colorScheme="red"
                          icon={<DeleteIcon />}
                          aria-label="Delete Knowledge Base"
                          onClick={() => handleDeleteKnowledgeBase(knowledgeBase.knowledgeBaseId)}
                          isDisabled={knowledgeBase.uploadStatus === "in_progress"}
                        />
                      </HStack>
                    </HStack>
                  </Box>
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
        </VStack>
      </Box>
    </Container>
  );
};

export default KnowledgeBase;
