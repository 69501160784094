import React, { useEffect, useState } from "react";
import { NodeData } from "../type";
import {
  FormControl,
  Tooltip,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  HStack,
  VStack,
  Text,
} from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { getGoogleAuthenticationOptions } from "../../../../../api/workflows";

interface GoogleNodeConfigProps {
  nodeData: NodeData;
  hiddenInputs: Record<string, boolean>;
  setNodeData: (nodeData: NodeData) => void;
  setHiddenInputs: (inputs: Record<string, boolean>) => void;
}

const GoogleNodeConfig: React.FC<GoogleNodeConfigProps> = ({
  nodeData,
  hiddenInputs,
  setNodeData,
  setHiddenInputs,
}) => {
  const [configOptions, setConfigOptions] = useState<
    {
      accessToken: string;
      refreshToken: string;
      userId: string;
      orgId: string;
    }[]
  >([]);
  const [selectedConfig, setSelectedConfig] = useState<{
    accessToken: string;
    refreshToken: string;
    userId: string;
    orgId: string;
  }>();

  useEffect(() => {
    getGoogleAuthenticationOptions().then((options) => {
      setConfigOptions((prev) => [...prev, options]);
      if (!selectedConfig) {
        setSelectedConfig(options);
        if (hiddenInputs.config) {
          setNodeData({
            ...nodeData,
            nodeInfo: {
              ...nodeData.nodeInfo,
              user_provided_variables: {
                ...nodeData.nodeInfo.user_provided_variables,
                config: {
                  user_id: options.userId,
                  organization_id: options.orgId || "1",
                },
              },
            },
          });
        }
      } else if (
        nodeData.nodeInfo.user_provided_variables?.config &&
        options.userId ===
          nodeData.nodeInfo.user_provided_variables.config.user_id &&
        options.orgId ===
          nodeData.nodeInfo.user_provided_variables.config.organization_id
      ) {
        setSelectedConfig(options);
      }
    });
  }, []);

  useEffect(() => {
    if (
      "config" in nodeData.nodeInfo.schema.inputs &&
      nodeData.nodeInfo.schema.inputs["config"] === "Dict[str, Any]"
    ) {
      setHiddenInputs({ config: true });
    }
  }, [setHiddenInputs, nodeData.nodeInfo.schema.inputs]);

  return (
    <>
      {nodeData.nodeInfo.schema.inputs &&
        Object.entries(nodeData.nodeInfo.schema.inputs).map(([name, _]) => (
          <FormControl key={name}>
            <FormLabel>{name}</FormLabel>
            <Tooltip
              isDisabled={hiddenInputs[name]}
              label="This input is connected via an incoming edge"
              placement="top"
            >
              {name === "config" ? (
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    isDisabled={!hiddenInputs[name]}
                    w="100%"
                    textAlign="left"
                    _disabled={{
                      opacity: 0.7,
                      cursor: "not-allowed",
                    }}
                  >
                    {selectedConfig
                      ? `Token: ...${selectedConfig.accessToken.slice(-8)}`
                      : "Select a config"}
                  </MenuButton>
                  <MenuList>
                    {configOptions.map((option, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          setSelectedConfig(option);
                          setNodeData({
                            ...nodeData,
                            nodeInfo: {
                              ...nodeData.nodeInfo,
                              user_provided_variables: {
                                ...nodeData.nodeInfo.user_provided_variables,
                                [name]: {
                                  user_id: option.userId,
                                  organization_id: option.orgId || "1", // TODO: fix this
                                },
                              },
                            },
                          });
                        }}
                      >
                        <HStack spacing={2}>
                          <FcGoogle size={20} />
                          <VStack align="start" spacing={0}>
                            <Text fontSize="sm">
                              Token: ...{option.accessToken.slice(-8)}
                            </Text>
                            <Text fontSize="xs" color="gray.500">
                              Refresh: ...{option.refreshToken.slice(-8)}
                            </Text>
                          </VStack>
                        </HStack>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              ) : (
                <Input
                  type="text"
                  value={
                    nodeData.nodeInfo.user_provided_variables?.[name] || ""
                  }
                  onChange={(e) => {
                    setNodeData({
                      ...nodeData,
                      nodeInfo: {
                        ...nodeData.nodeInfo,
                        user_provided_variables: {
                          ...nodeData.nodeInfo.user_provided_variables,
                          [name]: e.target.value,
                        },
                      },
                    });
                  }}
                  isDisabled={!hiddenInputs[name]}
                  _disabled={{
                    opacity: 0.7,
                    cursor: "not-allowed",
                  }}
                />
              )}
            </Tooltip>
          </FormControl>
        ))}
    </>
  );
};

export default GoogleNodeConfig;
