import React, { useState } from "react";
import { Text, Input, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { NodeData } from "../type";

interface TransferCallOptionsSectionProps {
  nodeData: NodeData;
  setNodeData: React.Dispatch<React.SetStateAction<NodeData>>;
}

const TransferCallOptionsSection: React.FC<TransferCallOptionsSectionProps> = ({
  nodeData,
  setNodeData,
}) => {
  const [error, setError] = useState<string>("");

  const validateE164 = (number: string): boolean => {
    const e164Regex = /^\+[1-9]\d{1,14}$/;
    return e164Regex.test(number);
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = e.target.value;

    if (number && !validateE164(number)) {
      setError("Please enter a valid E.164 format number (e.g., +12345678900)");
    } else {
      setError("");
    }

    setNodeData({
      ...nodeData,
      transferCallObject: {
        ...nodeData.transferCallObject,
        transferCall: true,
        transferCallNumber: number,
      },
    });
  };

  return (
    <>
      <Text fontSize="lg" fontWeight="bold" as="p">
        Transfer Call Number:
      </Text>
      <FormControl isInvalid={!!error}>
        <Input
          mt={2}
          placeholder="+12345678900"
          value={nodeData.transferCallObject?.transferCallNumber}
          onChange={handleNumberChange}
        />
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    </>
  );
};

export default TransferCallOptionsSection;
