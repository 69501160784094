import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Text,
  HStack,
  IconButton,
  VStack,
  Divider,
  Button,
  Container,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
  Spinner,
  Center,
  SimpleGrid,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon, SearchIcon, AddIcon } from "@chakra-ui/icons";
import { FaRegClone } from "react-icons/fa";
import {
  createOrganisationWorkflow,
  getOrganisationWorkflow,
  WorkflowConfig,
} from "../../api/workflows";
import {
  getOrganisationWorkflows,
  deleteOrganisationWorkflow,
} from "../../api/workflows";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";

const Workflows: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [workflows, setWorkflows] = useState<WorkflowConfig[]>([]);

  const filteredWorkflows = workflows.filter((workflow) =>
    (workflow.workflowName?.toLowerCase() ?? "").includes(
      searchQuery.toLowerCase()
    )
  );

  useEffect(() => {
    getWorkflows();
  }, []);

  const handleApiError = (error: unknown) => {
    const errorMessage =
      error instanceof Error
        ? error.message
        : axios.isAxiosError(error) && error.response?.data
        ? error.response.data
        : "Something went wrong. Please try again later.";
    toast({
      title: "Error",
      description: errorMessage,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const getWorkflows = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getOrganisationWorkflows("1");
      setWorkflows(response);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteWorkflow = useCallback(async (workflowId: string) => {
    if (window.confirm("Are you sure?")) {
      setIsLoading(true);
      try {
        await deleteOrganisationWorkflow("1", workflowId);
        setWorkflows((prevWorkflows) =>
          prevWorkflows.filter((workflow) => workflow.workflowId !== workflowId)
        );
      } catch (error) {
        handleApiError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const handleCreateWorkflow = useCallback(() => {
    navigate("/dashboard/workflow/new");
  }, [navigate]);

  const handleEditWorkflow = useCallback((workflowId: string) => {
    navigate(`/dashboard/workflow/${workflowId}`);
  }, []);

  const cloneWorkflow = useCallback(
    async (workflowId: string) => {
      setIsLoading(true);
      try {
        const workflowToClone = workflows.find(
          (workflow) => workflow.workflowId === workflowId
        );
        if (workflowToClone == null) {
          return;
        }
        const currentWorkflowConfiguration = await getOrganisationWorkflow(
          "1",
          workflowToClone.workflowId
        );
        const randomId = nanoid();
        const workflow: WorkflowConfig = await createOrganisationWorkflow("1", {
          ...currentWorkflowConfiguration,
          workflowName: `${
            workflowToClone.workflowName
          } (Clone-${randomId.slice(-3)})`,
        });
        navigate(`/dashboard/workflow/${workflow.workflowId}`);
      } catch (error) {
        handleApiError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [workflows]
  );

  return (
    <Container maxW="container.xl" px={4}>
      <Box height={"100%"}>
        <VStack align="stretch" spacing={6}>
          {/* Header */}
          <Text as="h1" fontSize="2xl" fontWeight="bold" py={4}>
            Workflows
          </Text>

          {/* Search and New Workflow Section */}
          <HStack spacing={4}>
            <InputGroup maxW="md">
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.400" />
              </InputLeftElement>
              <Input
                bg="white"
                placeholder="Search workflows..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                borderRadius="lg"
                _hover={{ borderColor: "teal.300" }}
                _focus={{ borderColor: "teal.500", boxShadow: "outline" }}
              />
            </InputGroup>

            <Divider orientation="vertical" height="40px" />

            <Button
              leftIcon={<AddIcon />}
              colorScheme="teal"
              variant="solid"
              onClick={handleCreateWorkflow}
              flexShrink={0}
            >
              New Workflow
            </Button>
          </HStack>

          {/* Workflows Grid */}
          {isLoading ? (
            <Center py={10}>
              <Spinner size="xl" color="teal.500" />
            </Center>
          ) : (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
              {filteredWorkflows.map((workflow) => (
                <Box
                  key={workflow.workflowId}
                  bg="white"
                  borderRadius="xl"
                  overflow="hidden"
                  border="1px solid"
                  borderColor="gray.200"
                  _hover={{ boxShadow: "md" }}
                  transition="all 0.2s"
                >
                  <VStack align="stretch" h="full">
                    {/* Workflow Header */}
                    <Box p={5} bg="gray.50">
                      <VStack align="start" spacing={2}>
                        <Text fontSize="lg" fontWeight="semibold" noOfLines={1}>
                          {workflow.workflowName}
                        </Text>
                        <Text fontSize="sm" color="gray.600" noOfLines={2}>
                          {workflow.workflowDescription || "No description"}
                        </Text>
                      </VStack>
                    </Box>

                    {/* Workflow Footer */}
                    <Box
                      mt="auto"
                      p={4}
                      borderTop="1px solid"
                      borderColor="gray.100"
                    >
                      <HStack justify="space-between">
                        <Text fontSize="sm" color="gray.600">
                          {workflow.updatedBy}
                        </Text>
                        <HStack spacing={2}>
                          <IconButton
                            size="sm"
                            variant="ghost"
                            colorScheme="teal"
                            icon={<EditIcon />}
                            aria-label="Edit Workflow"
                            onClick={() =>
                              handleEditWorkflow(workflow.workflowId)
                            }
                          />
                          <IconButton
                            size="sm"
                            variant="ghost"
                            colorScheme="teal"
                            icon={<FaRegClone />}
                            aria-label="Clone Workflow"
                            onClick={() => cloneWorkflow(workflow.workflowId)}
                          />
                          <IconButton
                            size="sm"
                            variant="ghost"
                            colorScheme="red"
                            icon={<DeleteIcon />}
                            aria-label="Delete Workflow"
                            onClick={() => deleteWorkflow(workflow.workflowId)}
                          />
                        </HStack>
                      </HStack>
                    </Box>
                  </VStack>
                </Box>
              ))}
            </SimpleGrid>
          )}
        </VStack>
      </Box>
    </Container>
  );
};

export default Workflows;
