interface AllowedWindow {
  start_time: string;
  end_time: string;
}

interface SchedulingRules {
  datetimes: string[];
  interval: string;
  NoAnswer: string;
  allowed_window: AllowedWindow[];
}

interface Customer {
  customer_name: string;
  phone_number: string;
  email?: string;
  additional_info?: Record<string, any>;
  customer_id?: number;
}

interface CampaignData {
  campaign: {
    campaign_name: string;
    campaign_description: string;
    max_attempts: number;
    scheduling_option: "INTERVAL" | "DATETIME";
    scheduling_rules: SchedulingRules;
    max_call_duration: number;
    campaign_start_datetime: number;
    campaign_end_datetime: number;
    pathway_id: number;
    voice_configuration_id: number;
    communication_channel: "CALL" | "SMS" | "WHATSAPP" | "EMAIL";
    status: "SCHEDULED" | "DRAFT";
    success_criteria: string;
  };
  number_ids: number[];
  customersData: {
    fileUrl?: string;
    customers?: Customer[];
  };
}

const convertToUTCTime = (time: string): string => {
  const [hours, minutes] = time.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);

  const utcHours = date.getUTCHours().toString().padStart(2, "0");
  const utcMinutes = date.getUTCMinutes().toString().padStart(2, "0");

  return `${utcHours}:${utcMinutes}`;
};

const convertToTimestamp = (dateTimeString: string): number => {
  return Math.floor(new Date(dateTimeString).getTime() / 1000);
};

const getCurrentTimestamp = (): number => {
  return Math.floor(Date.now() / 1000);
};

export const prepareCampaignData = (formData: any): CampaignData => {
  // Convert time windows to UTC
  const allowedWindow = formData.callWindows.map((window: any) => ({
    start_time: convertToUTCTime(window.start),
    end_time: convertToUTCTime(window.end),
  }));

  // Prepare scheduling rules
  const schedulingRules: SchedulingRules = {
    datetimes:
      formData.schedulingType === "datetime"
        ? formData.scheduledDateTimes.map((datetime: string) =>
            Math.floor(new Date(datetime).getTime() / 1000)
          )
        : [],
    interval:
      formData.schedulingType === "interval" ? `${formData.interval}` : "30",
    NoAnswer: `${formData.retryTime}`,
    allowed_window: allowedWindow,
  };

  // Prepare number_ids
  const number_ids: number[] = formData.selectedNumberIds;

  // Prepare customers data
  const customersData: { fileUrl?: string; customers?: Customer[] } = {};

  if (formData.dataSource === "file") {
    if (!formData.uploadedFileUrl) {
      throw new Error("File must be uploaded before creating campaign");
    }
    customersData.fileUrl = formData.uploadedFileUrl;
  } else if (formData.dataSource === "existing" && formData.selectedCustomers) {
    // For selected customers case
    customersData.customers = formData.selectedCustomers.map(
      (customer: any) => ({
        customer_name: customer.customer_name,
        phone_number: customer.phone_number,
        email: customer.email,
        additional_info: customer.additional_info,
        customer_id: customer.customer_id,
      })
    );
  }

  return {
    campaign: {
      campaign_name: formData.name,
      campaign_description: formData.description,
      max_attempts: formData.maxAttempts,
      scheduling_option: formData.schedulingType.toUpperCase(),
      scheduling_rules: schedulingRules,
      max_call_duration: formData.maxCallDuration,
      campaign_start_datetime:
        formData.startType === "immediate"
          ? getCurrentTimestamp()
          : convertToTimestamp(formData.startDateTime),
      campaign_end_datetime: convertToTimestamp(formData.endDateTime),
      pathway_id: formData.pathway,
      voice_configuration_id: formData.voiceConfig,
      communication_channel: formData.communicationChannel,
      status: "SCHEDULED",
      success_criteria: formData.successCriteria,
    },
    number_ids,
    customersData,
  };
};
