import { Node } from "@xyflow/react";

const categoryColorSchemes: Record<string, string> = {
  DocumentTools: "purple",
  GoogleTools: "green",
  LLMTools: "blue",
  BaseTools: "orange",
  DocumentReaders: "yellow",
};

export const areNodesEqual = (nodeA: Node, nodeB: Node): boolean => {
  const {
    position: positionA,
    selected: selectedA,
    measured: measuredA,
    dragging: draggingA,
    ...restA
  } = nodeA;
  const {
    position: positionB,
    selected: selectedB,
    measured: measuredB,
    dragging: draggingB,
    ...restB
  } = nodeB;
  const isSame = JSON.stringify(restA) === JSON.stringify(restB);
  return isSame;
};

export const areNodesLabelsValid = (nodes: Node[]) => {
  let valid: boolean = true;
  let nodeLabels = new Set();
  let errorNodes: string[] = [];

  nodes.forEach((node) => {
    const label = (node.data.label as string).toLowerCase().trim();
    if (nodeLabels.has(label)) {
      valid = false;
      errorNodes.push(label);
    } else {
      nodeLabels.add(label);
    }
  });
  return { valid, errorNodes };
};

export const areExtractionsValid = (nodes: Node[]) => {
  let valid: boolean = true;
  let errorNodes: string[] = [];

  nodes.forEach((node: Node) => {
    const label = (node.data.label as string).toLowerCase().trim();
    if (
      (
        node.data.callInfoIntoVariableObject as {
          extractInfoIntoVariable: boolean;
        }
      ).extractInfoIntoVariable
    ) {
      const fields = (
        node.data.callInfoIntoVariableObject as {
          variables: {
            varName: string;
            varType: string;
            varExample: string;
          }[];
        }
      ).variables;

      if (fields.length === 0) {
        valid = false;
        errorNodes.push(label);
      }

      fields.forEach((field) => {
        if (
          field.varName.trim() === "" ||
          field.varType.trim() === "" ||
          field.varExample.trim() === ""
        ) {
          valid = false;
          errorNodes.push(label);
        }
      });
    }
  });

  return { valid, errorNodes };
};

export const getCategoryColor = (category: string) => {
  return categoryColorSchemes[category];
};
