import React, { useEffect, useState } from "react";
import {
  Text,
  VStack,
  HStack,
  Button,
  Box,
  Alert,
  AlertIcon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Badge,
} from "@chakra-ui/react";
import { NodeData } from "../type";
import {
  getKnowledgeBaseMetadataList,
  KnowledgeBaseItem,
} from "../../../api/knowledgebase";
import MarkDownEditor from "../../MarkDownEditor";
import { ChevronDownIcon } from "@chakra-ui/icons";

interface KnowledgeBaseSectionProps {
  nodeData: NodeData;
  setNodeData: React.Dispatch<React.SetStateAction<NodeData>>;
}

const KnowledgeBaseSection: React.FC<KnowledgeBaseSectionProps> = ({
  nodeData,
  setNodeData,
}) => {
  const [knowledgeBases, setKnowledgeBases] = useState<KnowledgeBaseItem[]>([]);
  const [selectedInputType, setSelectedInputType] = useState<"kb" | "text">(
    nodeData.knowledgeBaseType === "kb" || nodeData.knowledgeBaseType === "text"
      ? nodeData.knowledgeBaseType
      : "kb"
  );

  useEffect(() => {
    console.log(nodeData);
  }, [nodeData]);

  useEffect(() => {
    const fetchKnowledgeBases = async () => {
      try {
        const organizationId = "1";
        const data = await getKnowledgeBaseMetadataList(organizationId);
        setKnowledgeBases(data);
      } catch (error) {
        console.error("Failed to fetch knowledge bases:", error);
      }
    };

    fetchKnowledgeBases();
  }, []);

  return (
    <>
      <Text>Knowledge Base:</Text>
      <VStack spacing={6} align="stretch">
        <HStack spacing={4} mb={4}>
          <Button
            flex={1}
            colorScheme="blue"
            variant={selectedInputType === "kb" ? "solid" : "outline"}
            onClick={() => setSelectedInputType("kb")}
          >
            Use Knowledge Base
          </Button>
          <Button
            flex={1}
            colorScheme="blue"
            variant={selectedInputType === "text" ? "solid" : "outline"}
            onClick={() => setSelectedInputType("text")}
          >
            Use Custom Text
          </Button>
        </HStack>

        {selectedInputType === "text" ? (
          <Box>
            <Box mb={2} fontWeight="bold">
              Custom Knowledge Text
            </Box>
            {nodeData.knowledgeBaseId && (
              <Alert status="warning" mb={2}>
                <AlertIcon />
                Using custom knowledge text will override the file based
                knowledge base selection.
              </Alert>
            )}
            <MarkDownEditor
              value={nodeData.knowledgeBaseText || ""}
              setValue={(value) => {
                setNodeData({
                  ...nodeData,
                  knowledgeBaseText: value,
                  knowledgeBaseType: "text",
                });
              }}
            />
          </Box>
        ) : (
          <Box>
            <Box mb={2} fontWeight="bold">
              Knowledge Base Selection
            </Box>
            {nodeData.knowledgeBaseText && (
              <Alert status="warning" mb={2}>
                <AlertIcon />
                Using file based knowledge base will override the custom
                knowledge text.
              </Alert>
            )}
            <Box position="relative" width="100%">
              <Menu matchWidth={true}>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  width="100%"
                  textAlign="left"
                  variant="outline"
                >
                  {knowledgeBases.find(
                    (kb) => kb.knowledgeBaseId === nodeData.knowledgeBaseId
                  )?.name || "Select knowledge base"}
                </MenuButton>
                <MenuList maxH="300px" overflowY="auto">
                  {knowledgeBases
                    .filter((kb) => kb.uploadStatus === "completed")
                    .map((kb) => (
                      <MenuItem
                        key={kb.knowledgeBaseId}
                        onClick={() =>
                          setNodeData({
                            ...nodeData,
                            knowledgeBaseId: kb.knowledgeBaseId,
                            knowledgeBaseType: kb.knowledgeBaseType,
                          })
                        }
                      >
                        <HStack justify="space-between" width="100%">
                          <Text>{kb.name}</Text>
                          <Badge
                            colorScheme="blue"
                            textTransform="capitalize"
                            fontSize="xs"
                            px={2}
                            py={1}
                            borderRadius="md"
                          >
                            {kb.knowledgeBaseType}
                          </Badge>
                        </HStack>
                      </MenuItem>
                    ))}
                </MenuList>
              </Menu>
            </Box>
          </Box>
        )}
      </VStack>
    </>
  );
};

export default KnowledgeBaseSection;
