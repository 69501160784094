import React, { useEffect, useState } from "react";
import { NodeData } from "../type";
import { Box, Text, VStack } from "@chakra-ui/react";

interface TextInputNodeConfigProps {
  nodeData: NodeData;
  setNodeData: (nodeData: NodeData) => void;
  setHiddenInputs: (inputs: Record<string, boolean>) => void;
}

const TextInputNodeConfig: React.FC<TextInputNodeConfigProps> = ({
  nodeData,
  setNodeData,
  setHiddenInputs,
}) => {
  const [text, setText] = useState<string>("");

  useEffect(() => {
    setHiddenInputs({ text: true });
    const existingText = nodeData.nodeInfo.user_provided_variables.text;
    if (existingText) {
      setText(existingText);
    }
  }, []);

  const handleTextChange = (value: string) => {
    setText(value);
    setNodeData({
      ...nodeData,
      nodeInfo: {
        ...nodeData.nodeInfo,
        user_provided_variables: {
          ...nodeData.nodeInfo.user_provided_variables,
          text: value,
        },
      },
    });
  };

  return (
    <Box p={4}>
      <VStack align="stretch" spacing={3}>
        <Text fontWeight="medium">Text Input</Text>
        <Box
          as="textarea"
          value={text}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleTextChange(e.target.value)
          }
          placeholder="Enter your text here..."
          minH="150px"
          w="100%"
          p={3}
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          _focus={{
            outline: "none",
            borderColor: "blue.500",
            boxShadow: "0 0 0 1px var(--chakra-colors-blue-500)",
          }}
          style={{
            resize: "none",
            background: "white",
          }}
        />
      </VStack>
    </Box>
  );
};

export default TextInputNodeConfig;
