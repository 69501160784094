import React, { useState, useRef } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  Button,
  VStack,
  Box,
  Text,
  HStack,
  IconButton,
  Textarea,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Tooltip,
  Center,
  Spinner,
  Divider,
} from "@chakra-ui/react";
import { FiEdit2, FiTrash2, FiClock } from "react-icons/fi";
import { Memory } from "../../types/memory";

interface MemoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  memories: Memory[];
  onEditMemory: (memoryId: string, message: string) => Promise<void>;
  onDeleteMemory: (memoryId: string) => Promise<void>;
  customerName: string;
  isLoading?: boolean;
}

export const MemoryModal: React.FC<MemoryModalProps> = ({
  isOpen,
  onClose,
  memories,
  onEditMemory,
  onDeleteMemory,
  customerName,
  isLoading = false,
}) => {
  const [editingMemory, setEditingMemory] = useState<{ id: string; message: string } | null>(null);
  const [isDeleting, setIsDeleting] = useState<string | null>(null);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const toast = useToast();

  const handleEdit = async () => {
    if (!editingMemory) return;
    try {
      await onEditMemory(editingMemory.id, editingMemory.message);
      setEditingMemory(null);
      toast({
        title: "Memory updated",
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: "Failed to update memory",
        status: "error",
        duration: 3000,
      });
    }
  };

  const handleDelete = async (memoryId: string) => {
    try {
      await onDeleteMemory(memoryId);
      setIsDeleting(null);
      toast({
        title: "Memory deleted",
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: "Failed to delete memory",
        status: "error",
        duration: 3000,
      });
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(date);
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="md"
      >
        <DrawerOverlay backdropFilter="blur(2px)" />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader 
            borderBottomWidth="1px" 
            bg="teal.500" 
            color="white"
            py={6}
          >
            <Text fontSize="lg">Memories for</Text>
            <Text fontSize="2xl" fontWeight="bold">{customerName}</Text>
          </DrawerHeader>

          <DrawerBody bg="gray.50">
            {isLoading ? (
              <Center h="100%">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="teal.500"
                  size="xl"
                />
              </Center>
            ) : (
              <VStack spacing={4} align="stretch" py={4}>
                {memories.map((memory) => (
                  <Box
                    key={memory.id}
                    p={6}
                    borderWidth="1px"
                    borderRadius="lg"
                    position="relative"
                    bg="white"
                    shadow="sm"
                    _hover={{
                      shadow: "md",
                      borderColor: "teal.200",
                    }}
                    transition="all 0.2s"
                  >
                    {editingMemory?.id === memory.id ? (
                      <VStack spacing={3}>
                        <Textarea
                          value={editingMemory.message}
                          onChange={(e) =>
                            setEditingMemory({ ...editingMemory, message: e.target.value })
                          }
                          minH="120px"
                          resize="vertical"
                          focusBorderColor="teal.400"
                        />
                        <HStack spacing={2} alignSelf="flex-end">
                          <Button 
                            size="sm" 
                            onClick={() => setEditingMemory(null)}
                            variant="ghost"
                          >
                            Cancel
                          </Button>
                          <Button 
                            size="sm" 
                            onClick={handleEdit} 
                            colorScheme="teal"
                            leftIcon={<FiEdit2 />}
                          >
                            Save
                          </Button>
                        </HStack>
                      </VStack>
                    ) : (
                      <>
                        <Box pr={20}>
                          <Text fontSize="md" lineHeight="tall">
                            {memory.memory}
                          </Text>
                          <HStack spacing={2} mt={4} color="gray.500" fontSize="sm">
                            <FiClock />
                            <Text>{formatDate(memory.created_at)}</Text>
                          </HStack>
                        </Box>
                        <HStack 
                          position="absolute" 
                          top={4} 
                          right={4}
                          bg="white" 
                          p={1} 
                          borderRadius="md"
                          spacing={1}
                          boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
                          _hover={{
                            boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)"
                          }}
                          transition="all 0.2s"
                        >
                          <Tooltip label="Edit memory" placement="top">
                            <IconButton
                              aria-label="Edit memory"
                              icon={<FiEdit2 />}
                              size="sm"
                              variant="ghost"
                              color="teal.600"
                              _hover={{ bg: 'teal.50' }}
                              onClick={() =>
                                setEditingMemory({ id: memory.id, message: memory.memory })
                              }
                            />
                          </Tooltip>
                          <Divider orientation="vertical" h="20px" />
                          <Tooltip label="Delete memory" placement="top">
                            <IconButton
                              aria-label="Delete memory"
                              icon={<FiTrash2 />}
                              size="sm"
                              variant="ghost"
                              color="red.500"
                              _hover={{ bg: 'red.50' }}
                              onClick={() => setIsDeleting(memory.id)}
                            />
                          </Tooltip>
                        </HStack>
                      </>
                    )}
                  </Box>
                ))}
                {!isLoading && memories.length === 0 && (
                  <Center py={8}>
                    <VStack spacing={3}>
                      <Text color="gray.500" fontSize="lg">
                        No memories found
                      </Text>
                      <Text color="gray.400" fontSize="sm">
                        This contact doesn't have any memories yet
                      </Text>
                    </VStack>
                  </Center>
                )}
              </VStack>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <AlertDialog
        isOpen={!!isDeleting}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsDeleting(null)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Memory
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsDeleting(null)}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => isDeleting && handleDelete(isDeleting)}
                ml={3}
                leftIcon={<FiTrash2 />}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}; 