import React from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  VStack,
  Heading,
  Icon,
  HStack,
} from "@chakra-ui/react";
import { Link, Outlet, useLocation } from "react-router-dom";
import SideNav from "../../components/SideNav";
import TopNav from "../../components/TopNav";
import { SidebarProvider, useSidebar } from "../../contexts/SidebarContext";
import {
  FiUsers,
  FiMessageCircle,
  FiPhoneCall,
  FiBook,
  FiGitBranch,
  FiBarChart,
} from "react-icons/fi";

interface DashboardCardProps {
  title: string;
  description: string;
  link: string;
  icon: React.ElementType;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  description,
  link,
  icon,
}) => (
  <Box
    as={Link}
    to={link}
    p={4}
    borderWidth="1px"
    borderRadius="xl"
    transition="all 0.2s"
    _hover={{
      transform: "translateY(-2px)",
      boxShadow: "md",
      borderColor: "teal.200",
      bg: "teal.50",
    }}
    bg="white"
    borderColor="gray.200"
    position="relative"
    overflow="hidden"
  >
    <VStack align="start" spacing={2}>
      <Box
        bg="teal.50"
        p={2}
        borderRadius="lg"
        color="teal.600"
        transition="color 0.2s"
      >
        <Icon as={icon} boxSize={5} />
      </Box>
      <VStack align="start" spacing={1}>
        <Heading
          size="md"
          fontWeight="semibold"
          color="gray.800"
          letterSpacing="tight"
          transition="color 0.2s"
          w="400px"
          maxW="100%"
        >
          {title}
        </Heading>
        <Text
          fontSize="sm"
          color="gray.600"
          lineHeight="tall"
          letterSpacing="tight"
          transition="color 0.2s"
          _hover={{ color: "gray.700" }}
          w="400px"
          maxW="100%"
        >
          {description}
        </Text>
      </VStack>
    </VStack>
  </Box>
);

interface DashboardItem {
  title: string;
  description: string;
  link: string;
  icon: React.ElementType;
  category: "Agent Design" | "Operations";
}

const DashboardContent: React.FC = () => {
  const { isExpanded } = useSidebar();
  const location = useLocation();

  const isRootDashboard = location.pathname === "/dashboard";

  const dashboardItems: DashboardItem[] = [
    {
      title: "Conversational Agents",
      description:
        "Create and customize AI agents with unique personalities, knowledge, and conversation styles",
      link: "/dashboard/agents",
      icon: FiMessageCircle,
      category: "Agent Design",
    },
    {
      title: "Voices",
      description:
        "Select and customize synthetic voices with adjustable pitch, speed, and speaking styles",
      link: "/dashboard/voices",
      icon: FiPhoneCall,
      category: "Agent Design",
    },
    {
      title: "Knowledge Base",
      description:
        "Upload documents and train your agents with custom information and response guidelines",
      link: "/dashboard/knowledge-base",
      icon: FiBook,
      category: "Agent Design",
    },
    {
      title: "Campaigns",
      description:
        "Schedule and monitor outbound calling campaigns with detailed analytics and reporting",
      link: "/dashboard/campaigns",
      icon: FiUsers,
      category: "Operations",
    },
    {
      title: "Contacts",
      description:
        "Import, segment, and manage contact lists with custom attributes and engagement history",
      link: "/dashboard/contacts",
      icon: FiUsers,
      category: "Operations",
    },
    {
      title: "Workflows",
      description:
        "Design automated sequences combining calls, messages, and conditional logic",
      link: "/dashboard/workflows",
      icon: FiGitBranch,
      category: "Agent Design",
    },
    {
      title: "Fine Tuning",
      description: "Fine tune your agents with custom prompts and data",
      link: "/dashboard/fine-tuning",
      icon: FiBarChart,
      category: "Agent Design",
    },
  ];

  return (
    <Flex height="100vh" flexDirection="column">
      <Flex flex={1} overflow="hidden" position="relative">
        <Box
          position="absolute"
          height="100%"
          width={isExpanded ? "240px" : "60px"}
          zIndex={20}
          transition="width 0.3s"
        >
          <SideNav />
        </Box>
        <Box flex={1} overflowY="auto" bg="whiteAlpha.200" marginLeft="60px">
          <TopNav />
          <Box p={8} height="calc(100vh - 64px)" position="relative">
            {isRootDashboard ? (
              <VStack spacing={4} align="stretch" maxW="1200px" mx="auto">
                <VStack spacing={8} align="stretch">
                  {["Agent Design", "Operations"].map((category) => (
                    <Box key={category}>
                      <HStack spacing={4} mb={6} align="center">
                        <Heading size="md" color="gray.700">
                          {category}
                        </Heading>
                        <Box flex={1} height="1px" bg="gray.200" />
                      </HStack>
                      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                        {dashboardItems
                          .filter((item) => item.category === category)
                          .map((item, index) => (
                            <DashboardCard key={index} {...item} />
                          ))}
                      </SimpleGrid>
                    </Box>
                  ))}
                </VStack>
              </VStack>
            ) : (
              <Box position="absolute" top={0} left={0} right={0} bottom={0}>
                <Outlet />
              </Box>
            )}
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

const Dashboard: React.FC = () => {
  return (
    <SidebarProvider>
      <DashboardContent />
    </SidebarProvider>
  );
};

export default Dashboard;
