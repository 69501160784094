import { useState, useEffect } from "react";
import { Workflow } from "../type";
import { getOrganisationWorkflows } from "../../../api/workflows";

export const useAvailableWorkflows = (nodeType?: string) => {
  const [availableWorkflows, setAvailableWorkflows] = useState<Workflow[]>([]);

  useEffect(() => {
    const fetchWorkflows = async () => {
      if (nodeType === "WorkflowNode") {
        try {
          const response = await getOrganisationWorkflows();
          const workflowsWithIds = response.map(
            (workflow: Workflow, index: number) => ({
              ...workflow,
              id: index,
            })
          );
          setAvailableWorkflows(workflowsWithIds);
          console.log(workflowsWithIds);
        } catch (error) {
          console.error("Error fetching workflows:", error);
        }
      }
    };

    fetchWorkflows();
  }, [nodeType]);

  return { availableWorkflows };
};
