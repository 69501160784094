import React from "react";
import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from "@xyflow/react";
import { Box } from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { WorkflowEdgeProps } from "./type";

const WorkflowEdge: React.FC<WorkflowEdgeProps> = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  style = {},
  data,
}: WorkflowEdgeProps) => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const iconStyle = {
    background: "white",
    border: "1px solid #ddd",
    borderRadius: "50%",
    padding: "2px",
    boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
    position: "absolute",
    zIndex: 1,
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <Box
          position={"absolute"}
          transform={`translate(-50%, -50%) translate(${labelX}px,${labelY}px)`}
          pointerEvents={"all"}
          className="nodrag nopan"
          cursor={"pointer"}
          onClick={() => console.log("Add action for source + icon")}
          sx={iconStyle}
        >
          <FaPlus size={10} onClick={data.handleAddNode} color="#666" />
        </Box>
      </EdgeLabelRenderer>
    </>
  );
};

export default WorkflowEdge;
