import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Text,
  HStack,
  Icon,
  IconButton,
  VStack,
  Tooltip,
  FormLabel,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Switch,
} from "@chakra-ui/react";
import { Handle, Position, useReactFlow } from "@xyflow/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import { FaRegClone } from "react-icons/fa";
import { MdDeleteOutline, MdOutlineWifiCalling3 } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";

import {
  DynamicInputVariable,
  ExtractedVariable,
  NodeData,
  NodeViewProps,
} from "./type";
import { availableNodeTypes } from "./NodeTypes";
import { nanoid } from "nanoid";
import { getCategoryColor } from "../../helpers/WorkflowBuilder.helper";
import { getWorkflowConditions } from "../../../../api/workflows";
import { ConditionGroup } from "./type";
import DefaultNodeConfig from "./Configurations/DefaultNodeConfig";
import ConditionalNodeConfig from "./Configurations/ConditionalNodeConfig";
import InputNodeConfig from "./Configurations/InputNodeConfig";
import DocumentReaderNodeConfig from "./Configurations/DocumentReaderNodeConfig";
import GoogleNodeConfig from "./Configurations/GoogleNodeConfig";
import ExtractVariablesConfig from "./Configurations/ExtractVariablesConfig";
import FormatTextNodeConfig from "./Configurations/FormatTextNodeConfig";
import TextInputNodeConfig from "./Configurations/TextInputNodeConfig";

const getNodeIcon = (nodeType: string) => {
  const node = availableNodeTypes.filter((node) => node.type === nodeType)[0];
  return node.icon || MdOutlineWifiCalling3;
};

const getNodeCategory = (nodeType: string) => {
  const node = availableNodeTypes.filter((node) => node.type === nodeType)[0];
  return node.name;
};

const NodeView: React.FC<NodeViewProps> = ({
  id,
  data,
  selected = false,
}: NodeViewProps) => {
  const [nodeData, setNodeData] = useState<NodeData>({ ...data });
  const { deleteElements, addNodes, getNode, updateNodeData } = useReactFlow();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isGoogleTool = nodeData.nodeInfo.name.toLowerCase().includes("google");
  const isConditional = nodeData.label === "If/Else";
  const isInputNode = nodeData.label === "Input";
  const isDocumentReader = nodeData.type === "DocumentReaders";
  const isExtractVariables = nodeData.label === "Extract Variables";
  const isFormatText = nodeData.label === "Format Text";
  const isTextInput = nodeData.label === "Input Text";
  const [dynamicInputs, setDynamicInputs] = useState<DynamicInputVariable[]>(
    () => {
      let inputs: DynamicInputVariable[] = [];
      if (data.nodeInfo?.schema?.inputs) {
        inputs = Object.entries(data.nodeInfo.schema.inputs)
          .filter(([name, _]) => name !== "kwargs")
          .map(([name, type]) => ({ name, type }));
      }
      inputs.push({ name: "input1", type: "str" });
      return inputs;
    }
  );
  const [hiddenInputs, setHiddenInputs] = useState<Record<string, boolean>>({});
  const [conditions, setConditions] = useState<Record<string, string>>({});
  const [extractedVariables, setExtractedVariables] = useState<
    ExtractedVariable[]
  >(() => {
    if (data.nodeInfo?.user_provided_variables?.variable_schema) {
      return data.nodeInfo.user_provided_variables.variable_schema;
    }
    return [{ name: "", type: "string" }];
  });
  const [inputVariables, setInputVariables] = useState<
    {
      name: string;
      type: string;
      value: string;
    }[]
  >(() => {
    if (data.nodeInfo?.inputVariables) {
      return data.nodeInfo.inputVariables;
    }
    return [{ name: "", type: "string", value: "" }];
  });
  const [conditionGroup, setConditionGroup] = useState<ConditionGroup>(() => {
    if (data.nodeInfo?.conditionGroup) {
      return {
        conditions: data.nodeInfo.conditionGroup.conditions,
        logicalOperator: data.nodeInfo.conditionGroup.logicalOperator || "AND",
      };
    }
    return {
      conditions: [{ input: "", operator: "", value: "" }],
      logicalOperator: "AND",
    };
  });
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (data.nodeInfo?.user_provided_variables) {
      const newHiddenInputs: Record<string, boolean> = {};
      Object.keys(data.nodeInfo.user_provided_variables).forEach((key) => {
        newHiddenInputs[key] = true;
      });
      setHiddenInputs((prev) => ({ ...prev, ...newHiddenInputs }));
    }
  }, [data.nodeInfo?.user_provided_variables]);

  useEffect(() => {
    if (isConditional) {
      getWorkflowConditions().then((res) => {
        setConditions(res.data);
      });
    }
  }, [isConditional]);

  const onDelete = useCallback(() => {
    deleteElements({ nodes: [{ id }] });
  }, [id, deleteElements]);

  const duplicateNode = useCallback(() => {
    const node: any = getNode(id);
    const position = {
      x: node.position.x + 350,
      y: node.position.y + 0,
    };

    const newNodeId = nanoid();
    const newNodeName = `${node.data.label} (Clone-${newNodeId.slice(-3)})`;

    addNodes({
      ...node,
      selected: false,
      dragging: false,
      id: newNodeId,
      data: { ...node.data, label: newNodeName },
      position,
    });
  }, [id, getNode, addNodes]);

  const icon = getNodeIcon(data.type);
  const nodeText = data.label;
  const nodeTypeName = getNodeCategory(data.type);

  const [isExpanded, setIsExpanded] = React.useState(true);

  const colorScheme = getCategoryColor(data.type);

  const handleNodeDataUpdate = useCallback(
    (newData: NodeData) => {
      setNodeData(newData);
      updateNodeData(id, newData);
    },
    [id, updateNodeData]
  );

  useEffect(() => {
    if (isConditional) {
      handleNodeDataUpdate({
        ...nodeData,
        nodeInfo: {
          ...nodeData.nodeInfo,
          conditionGroup,
        },
      });
    } else if (isInputNode) {
      // Create outputs object from input variables
      const outputs: Record<string, string> = {};
      inputVariables.forEach((variable) => {
        if (variable.name) {
          outputs[variable.name] = variable.type;
        }
      });

      handleNodeDataUpdate({
        ...nodeData,
        nodeInfo: {
          ...nodeData.nodeInfo,
          inputVariables,
          schema: {
            ...nodeData.nodeInfo.schema,
            outputs,
          },
        },
      });
    } else if (isExtractVariables) {
      const outputs: Record<string, string> = {};
      extractedVariables.forEach((variable) => {
        if (variable.name) {
          outputs[variable.name] = variable.type;
        }
      });

      handleNodeDataUpdate({
        ...nodeData,
        nodeInfo: {
          ...nodeData.nodeInfo,
          user_provided_variables: {
            variable_schema: extractedVariables,
          },
          schema: {
            ...nodeData.nodeInfo.schema,
            outputs,
          },
        },
      });
    } else if (isFormatText) {
      handleNodeDataUpdate({
        ...nodeData,
        nodeInfo: {
          ...nodeData.nodeInfo,
          schema: {
            ...nodeData.nodeInfo.schema,
            inputs: dynamicInputs.reduce((acc, input) => {
              console.log(input);
              acc[input.name] = input.type;
              return acc;
            }, {} as Record<string, string>),
          },
        },
      });
    }
  }, [
    id,
    isConditional,
    isInputNode,
    isExtractVariables,
    isFormatText,
    conditionGroup,
    inputVariables,
    extractedVariables,
    dynamicInputs,
  ]);

  return (
    <Box position="relative">
      {data.nodeInfo.schema &&
        Object.entries(data.nodeInfo.schema.inputs || {}).map(
          ([name, _], index, array) => {
            if (hiddenInputs[name]) return null;
            const padding = 0.1;
            const usableWidth = 1 - 2 * padding;
            const position =
              array.length === 1
                ? 0.5
                : padding + index * (usableWidth / (array.length - 1));
            return (
              <Box key={name} position="absolute" top="-12px" width="100%">
                <Text
                  position="absolute"
                  fontSize="xs"
                  bg={`${colorScheme}.50`}
                  px={2}
                  py={0}
                  borderRadius="full"
                  transform="translate(-50%, -150%)"
                  left={`${position * 100}%`}
                  whiteSpace="nowrap"
                  fontWeight="medium"
                  color={`${colorScheme}.700`}
                >
                  {name}
                </Text>
                <Handle
                  type="target"
                  position={Position.Top}
                  id={name}
                  style={{
                    left: `${position * 100}%`,
                    width: "10px",
                    height: "10px",
                    background: `var(--chakra-colors-${colorScheme}-100)`,
                    border: `2px solid var(--chakra-colors-${colorScheme}-500)`,
                  }}
                />
              </Box>
            );
          }
        )}

      <HStack
        alignItems="flex-start"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box
          border="1px solid"
          borderColor={`${colorScheme}.200`}
          bgColor={`${colorScheme}.50`}
          borderRadius="xl"
          width={400}
          overflow="clip"
          boxShadow="sm"
          _hover={{ boxShadow: "md" }}
          transition="box-shadow 0.2s"
        >
          <VStack align="stretch" spacing={0}>
            <HStack p={4} spacing={3}>
              <Box
                bg={`${colorScheme}.100`}
                p={3}
                borderRadius="lg"
                color={`${colorScheme}.700`}
              >
                <Icon as={icon} boxSize={6} />
              </Box>
              <VStack align="start" spacing={0} flex={1}>
                <Text color="gray.500" fontSize="sm" fontWeight="medium">
                  {nodeTypeName}
                </Text>
                <Text fontWeight="medium" fontSize="lg">
                  {nodeText}
                </Text>
              </VStack>
              <IconButton
                aria-label="Expand node"
                icon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                size="sm"
                variant="ghost"
                onClick={() => setIsExpanded(!isExpanded)}
              />
            </HStack>

            {data.nodeInfo.schema.description && (
              <Text px={4} pb={4} color="gray.600" fontSize="sm">
                {data.nodeInfo.schema.description}
              </Text>
            )}

            {data.nodeInfo.schema && (
              <VStack align="stretch" spacing={2} p={4} bg="white">
                {isExpanded && (
                  <Box p={4} bg="gray.50" borderRadius="md">
                    <Text fontSize="sm" fontWeight="semibold" color="gray.700">
                      Configuration
                    </Text>
                    {!isInputNode &&
                      !isConditional &&
                      !isDocumentReader &&
                      !isGoogleTool &&
                      !isExtractVariables &&
                      !isFormatText &&
                      !isTextInput && (
                        <DefaultNodeConfig
                          nodeData={nodeData}
                          setNodeData={handleNodeDataUpdate}
                          hiddenInputs={hiddenInputs}
                        />
                      )}
                    {!isInputNode &&
                      isConditional &&
                      !isDocumentReader &&
                      !isTextInput && (
                        <ConditionalNodeConfig
                          nodeData={nodeData}
                          conditionGroup={conditionGroup}
                          setConditionGroup={setConditionGroup}
                          conditions={conditions}
                        />
                      )}
                    {isInputNode && !isDocumentReader && !isTextInput && (
                      <InputNodeConfig
                        inputVariables={inputVariables}
                        setInputVariables={setInputVariables}
                      />
                    )}
                    {!isInputNode && isExtractVariables && !isTextInput && (
                      <ExtractVariablesConfig
                        extractedVariables={extractedVariables}
                        setExtractedVariables={setExtractedVariables}
                        setHiddenInputs={setHiddenInputs}
                      />
                    )}
                    {!isInputNode && isFormatText && !isTextInput && (
                      <FormatTextNodeConfig
                        nodeData={nodeData}
                        setNodeData={handleNodeDataUpdate}
                        dynamicInputs={dynamicInputs}
                        setDynamicInputs={setDynamicInputs}
                        setHiddenInputs={setHiddenInputs}
                      />
                    )}
                    {isTextInput && (
                      <TextInputNodeConfig
                        nodeData={nodeData}
                        setNodeData={handleNodeDataUpdate}
                        setHiddenInputs={setHiddenInputs}
                      />
                    )}
                    {isDocumentReader && (
                      <DocumentReaderNodeConfig
                        setNodeData={handleNodeDataUpdate}
                        nodeData={nodeData}
                        setHiddenInputs={setHiddenInputs}
                      />
                    )}
                    {isGoogleTool && (
                      <GoogleNodeConfig
                        nodeData={nodeData}
                        hiddenInputs={hiddenInputs}
                        setNodeData={handleNodeDataUpdate}
                        setHiddenInputs={setHiddenInputs}
                      />
                    )}
                  </Box>
                )}
              </VStack>
            )}
          </VStack>
        </Box>

        {(selected || isHovered) && (
          <Box
            position="absolute"
            right="-48px"
            top={4}
            bg={`${colorScheme}.50`}
            borderRadius="lg"
            p={1}
          >
            <VStack spacing={2}>
              <Tooltip label="Delete Node" placement="right">
                <IconButton
                  variant="ghost"
                  colorScheme="red"
                  aria-label="Delete Node"
                  size="sm"
                  onClick={onDelete}
                  icon={<MdDeleteOutline />}
                />
              </Tooltip>
              <Tooltip label="Clone Node" placement="right">
                <IconButton
                  variant="ghost"
                  colorScheme="blue"
                  aria-label="Clone Node"
                  size="sm"
                  onClick={duplicateNode}
                  icon={<FaRegClone />}
                />
              </Tooltip>
              <Tooltip label="Configure Inputs" placement="right">
                <IconButton
                  variant="ghost"
                  colorScheme="gray"
                  aria-label="Configure Inputs"
                  size="sm"
                  onClick={onOpen}
                  icon={<IoSettingsOutline />}
                />
              </Tooltip>
            </VStack>
          </Box>
        )}
      </HStack>

      <Box position="absolute" bottom="-12px" width="100%">
        {data.nodeInfo.schema &&
          Object.entries(data.nodeInfo.schema.outputs || {}).map(
            ([name, _], index, array) => {
              const padding = 0.1;
              const usableWidth = 1 - 2 * padding;
              const position =
                array.length === 1
                  ? 0.5
                  : padding + index * (usableWidth / (array.length - 1));
              return (
                <Box key={name} position="relative">
                  <Text
                    position="absolute"
                    fontSize="xs"
                    bg={`${colorScheme}.50`}
                    px={2}
                    py={0}
                    borderRadius="full"
                    transform="translate(-50%, 100%)"
                    left={`${position * 100}%`}
                    whiteSpace="nowrap"
                    fontWeight="medium"
                    color={`${colorScheme}.700`}
                  >
                    {name}
                  </Text>
                  <Handle
                    type="source"
                    position={Position.Bottom}
                    id={index.toString()}
                    style={{
                      position: "absolute",
                      left: `${position * 100}%`,
                      width: "10px",
                      height: "10px",
                      background: `var(--chakra-colors-${colorScheme}-100)`,
                      border: `2px solid var(--chakra-colors-${colorScheme}-500)`,
                    }}
                  />
                </Box>
              );
            }
          )}
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Configure Inputs</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {data.nodeInfo.schema?.inputs &&
              Object.entries(data.nodeInfo.schema.inputs).map(([name, _]) => (
                <FormControl
                  key={name}
                  display="flex"
                  alignItems="center"
                  mb={4}
                >
                  <FormLabel mb={0}>{name}</FormLabel>
                  <Switch
                    isChecked={!hiddenInputs[name]}
                    onChange={(e) =>
                      setHiddenInputs({
                        ...hiddenInputs,
                        [name]: !e.target.checked,
                      })
                    }
                  />
                </FormControl>
              ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default NodeView;
