import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  VStack,
  Text,
  Box,
  Icon,
  Input,
} from "@chakra-ui/react";
import { FiFile, FiCheck } from "react-icons/fi";

interface UploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedFile: File | null;
  isUploading: boolean;
  onFileSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUpload: () => Promise<void>;
}

export const UploadModal: React.FC<UploadModalProps> = ({
  isOpen,
  onClose,
  selectedFile,
  isUploading,
  onFileSelect,
  onUpload,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Contacts</ModalHeader>
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Text color="gray.600">
              Upload a CSV file containing customer data. The file must include
              'customer_name' and 'phone_number' columns.
            </Text>
            <Box
              borderWidth={2}
              borderRadius="md"
              borderStyle="dashed"
              p={6}
              textAlign="center"
              bg="gray.50"
            >
              {selectedFile ? (
                <VStack spacing={2}>
                  <Icon as={FiCheck} color="green.500" boxSize={6} />
                  <Text fontWeight="medium">{selectedFile.name}</Text>
                  <Text fontSize="sm" color="gray.500">
                    {(selectedFile.size / 1024).toFixed(2)} KB
                  </Text>
                </VStack>
              ) : (
                <VStack spacing={2}>
                  <Icon as={FiFile} color="gray.400" boxSize={6} />
                  <Text fontWeight="medium">Drop your file here or</Text>
                  <Button as="label" size="sm" colorScheme="blue" cursor="pointer">
                    Browse
                    <Input
                      type="file"
                      accept=".csv"
                      hidden
                      onChange={onFileSelect}
                    />
                  </Button>
                </VStack>
              )}
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose} isDisabled={isUploading}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={onUpload}
            isLoading={isUploading}
            loadingText="Uploading..."
            isDisabled={!selectedFile}
          >
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}; 