import { apiWithAuth } from "../apiService";

interface SchedulingWindow {
  start_time: string;
  end_time: string;
}

interface SchedulingRules {
  datetimes: string[];
  interval: string;
  NoAnswer: string;
  allowed_window: SchedulingWindow[];
}

interface Organization {
  id: number;
  name: string;
  createDate: string;
  updateDate: string;
}

interface User {
  id: number;
  email: string;
}

interface CommunicationNumber {
  created_at: string;
  updated_at: string;
  number_id: number;
  number: string;
  sms_enabled: boolean;
  voice_enabled: boolean;
  provider: string;
  sid: string;
  status: string;
}

interface CampaignCustomer {
  created_at: string;
  updated_at: string;
  campaign_customer_id: number;
  attempt_count: number;
  status: string;
  next_call_datetime: string | null;
}

interface Customer {
  created_at: string;
  updated_at: string;
  customer_id: number;
  customer_name: string;
  phone_number: string;
  email: string;
  additional_info: Record<string, any>;
  source: string;
}

export interface CampaignCustomerDetails {
  created_at: string;
  updated_at: string;
  campaign_customer_id: number;
  attempt_count: number;
  status: string;
  next_call_datetime: string | null;
  customer: Customer;
}

interface PaginatedResponse<T> {
  success: boolean;
  message: string;
  data: T[];
  pagination: {
    total: number;
    page: number;
    pages: number;
    limit: number;
  };
}

export interface Campaign {
  created_at: string;
  updated_at: string;
  campaign_id: number;
  campaign_name: string;
  campaign_description: string;
  max_attempts: number;
  scheduling_option: string;
  scheduling_rules: SchedulingRules;
  max_call_duration: number;
  campaign_start_datetime: string;
  campaign_end_datetime: string;
  pathway_id: string;
  voice_configuration_id: string;
  communication_channel: string;
  status: string;
  success_criteria: string;
  organization: Organization;
  user: User;
  communication_numbers: CommunicationNumber[];
  campaign_customers: CampaignCustomer[];
}

interface ApiResponse<T> {
  success: boolean;
  data?: T;
  message?: string;
}

export interface CallSummary {
  created_at: string;
  call_summary_id: number;
  summary_text: string;
  sentiment: string;
}

export interface CallInteraction {
  created_at: string;
  updated_at: string;
  call_id: number;
  call_duration: number;
  status: string;
  result: string;
  campaign_number: string;
  max_duration_exceeded: boolean;
  carrier_call_id: string;
  carrier: string;
  conversation_agent_call_id: string;
  conversation_agent_provider: string;
  summary: CallSummary;
}

interface TranscriptionMessage {
  role: "USER" | "AI";
  content: string;
}

export interface CallTranscription {
  created_at: string;
  call_transcription_id: number;
  message: TranscriptionMessage;
}

interface CommunicationNumberResponse {
  success: boolean;
  data: CommunicationNumber[];
}

export const createCampaign = async (data: any): Promise<Campaign> => {
  const response = await apiWithAuth.post<ApiResponse<Campaign>>(
    "/campaigns",
    data
  );

  if (!response.data.success) {
    throw new Error(response.data.message || "Failed to create campaign");
  }

  return response.data.data!;
};

export const listCampaigns = async (): Promise<Campaign[]> => {
  const response = await apiWithAuth.get<ApiResponse<Campaign[]>>("/campaigns");

  if (!response.data.success) {
    throw new Error(response.data.message || "Failed to fetch campaigns");
  }

  return response.data.data!;
};

export const getCampaign = async (campaignId: number): Promise<Campaign> => {
  const response = await apiWithAuth.get<ApiResponse<Campaign>>(
    `/campaigns/${campaignId}`
  );
  if (!response.data.success) {
    throw new Error(response.data.message || "Failed to fetch campaign");
  }

  return response.data.data!;
};

export const getAvailableNumbers =
  async (): Promise<CommunicationNumberResponse> => {
    const response = await apiWithAuth.get<CommunicationNumberResponse>(
      "/communication-numbers"
    );
    return response.data;
  };

export const getCampaignCustomers = async (
  campaignId: number,
  page: number = 1,
  limit: number = 10
): Promise<PaginatedResponse<CampaignCustomerDetails>> => {
  const response = await apiWithAuth.get<
    PaginatedResponse<CampaignCustomerDetails>
  >(`/campaigns/${campaignId}/customers?page=${page}&limit=${limit}`);

  if (!response.data.success) {
    throw new Error(
      response.data.message || "Failed to fetch campaign customers"
    );
  }

  return response.data;
};

export const getCampaignCustomerInteractions = async (
  campaignId: number,
  campaignCustomerId: number
): Promise<CallInteraction[]> => {
  const response = await apiWithAuth.get<ApiResponse<CallInteraction[]>>(
    `/campaigns/${campaignId}/campaign-customers/${campaignCustomerId}/interactions`
  );

  if (!response.data.success) {
    throw new Error(
      response.data.message || "Failed to fetch customer interactions"
    );
  }

  return response.data.data!;
};

export const getCallTranscriptions = async (
  callId: number
): Promise<CallTranscription[]> => {
  const response = await apiWithAuth.get<ApiResponse<CallTranscription[]>>(
    `/transcriptions/${callId}`
  );

  if (!response.data.success) {
    throw new Error(
      response.data.message || "Failed to fetch call transcriptions"
    );
  }

  return response.data.data!;
};

export const updateCampaign = async (
  campaignId: number,
  data: Record<string, any>
): Promise<void> => {
  const response = await apiWithAuth.patch<ApiResponse<any>>(
    `/campaigns/${campaignId}`,
    data
  );

  if (!response.data.success) {
    throw new Error(response.data.message || "Failed to update campaign");
  }

  // If you need to handle the response data, you can return it here
  return response.data.data;
};
