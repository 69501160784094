import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  HStack,
  VStack,
  Button,
  IconButton,
  Spinner,
  Center,
  useToast,
  Tooltip,
  Container,
  SimpleGrid,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { listCampaigns, Campaign } from "../../api/campaigns";
import { TbListDetails } from "react-icons/tb";

const Campaigns: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const fetchCampaigns = async () => {
    try {
      setIsLoading(true);
      const fetchedCampaigns = await listCampaigns();
      setCampaigns(fetchedCampaigns);
    } catch (error) {
      console.error("Error fetching campaigns:", error);
      toast({
        title: "Error",
        description:
          error instanceof Error ? error.message : "Failed to fetch campaigns",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateCampaign = () => {
    navigate("/dashboard/campaigns/create");
  };

  const handleViewCampaign = (campaignId: number) => {
    navigate(`/dashboard/campaigns/${campaignId}`);
  };

  const formatDate = (timestamp: string) => {
    return new Date(parseInt(timestamp) * 1000).toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <Container maxW="container.xl" px={4}>
      <Box height={"100%"}>
        <VStack align="stretch" spacing={6}>
          <Text as="h1" fontSize="2xl" fontWeight="bold" py={4}>
            Campaigns
          </Text>

          <HStack spacing={4}>
            <Button
              colorScheme="teal"
              variant="solid"
              onClick={handleCreateCampaign}
              flexShrink={0}
            >
              + New Campaign
            </Button>
          </HStack>

          {isLoading ? (
            <Center py={10}>
              <Spinner size="xl" color="teal.500" />
            </Center>
          ) : (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
              {campaigns.length === 0 ? (
                <Box width="100%" textAlign="center" py={8}>
                  <Text color="gray.500" fontSize="lg">
                    No Campaigns to show
                  </Text>
                </Box>
              ) : (
                campaigns.map((campaign) => (
                  <Box
                    key={campaign.campaign_id}
                    bg="white"
                    borderRadius="xl"
                    overflow="hidden"
                    border="1px solid"
                    borderColor="gray.200"
                    _hover={{ boxShadow: "md" }}
                    transition="all 0.2s"
                  >
                    <VStack align="stretch" h="full">
                      <Box p={5} bg="gray.50">
                        <VStack align="start" spacing={2} width={"90%"} overflow={"hidden"}>
                          <Text fontSize="lg" fontWeight="semibold" noOfLines={1}>
                            {campaign.campaign_name}
                          </Text>
                          <Text fontSize="sm" noOfLines={2}>
                            {campaign.campaign_description}
                          </Text>
                          <Text fontSize="sm">Status: {campaign.status}</Text>
                          <Text fontSize="sm">
                            Created: {formatDate(campaign.created_at)}
                          </Text>
                        </VStack>
                      </Box>

                      <Box
                        mt="auto"
                        p={4}
                        borderTop="1px solid"
                        borderColor="gray.100"
                      >
                        <HStack justify="space-between">
                          <Text fontSize="sm" color="gray.600">
                            {campaign.user.email}
                          </Text>
                          <HStack spacing={2}>
                            <Tooltip label="View Campaign">
                              <IconButton
                                size="sm"
                                variant="ghost"
                                colorScheme="teal"
                                aria-label="View Campaign"
                                icon={<TbListDetails />}
                                onClick={() => handleViewCampaign(campaign.campaign_id)}
                              />
                            </Tooltip>
                          </HStack>
                        </HStack>
                      </Box>
                    </VStack>
                  </Box>
                ))
              )}
            </SimpleGrid>
          )}
        </VStack>
      </Box>
    </Container>
  );
};

export default Campaigns;
