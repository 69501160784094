import React, { useEffect } from "react";
import { VStack, Text, Select } from "@chakra-ui/react";
import { NodeData, Workflow } from "../type";

interface WorkflowSectionProps {
  nodeData: NodeData;
  setNodeData: React.Dispatch<React.SetStateAction<NodeData>>;
  availableWorkflows: Workflow[];
}

const WorkflowSection: React.FC<WorkflowSectionProps> = ({
  nodeData,
  setNodeData,
  availableWorkflows,
}) => {
  useEffect(() => {
    console.log(nodeData);
  }, [nodeData]);

  const selectedWorkflowName = availableWorkflows.find(
    (w) => w.workflowId === nodeData.workflows?.[0]
  )?.workflowName || "";

  return (
    <VStack alignItems="start">
      <Text fontSize="lg" fontWeight="bold" as="p">
        Select Workflow:
      </Text>
      <Select
        placeholder="Select Workflow"
        value={selectedWorkflowName}
        onChange={(e) => {
          const selectedWorkflow = availableWorkflows.find(
            (w) => w.workflowName === e.target.value
          );
          setNodeData((prevState) => ({
            ...prevState,
            workflows: [selectedWorkflow?.workflowId || ""],
          }));
        }}
        overflow="hidden"
      >
        {availableWorkflows.map((workflow) => (
          <option key={workflow.id} value={workflow.workflowName}>
            {workflow.workflowName}
          </option>
        ))}
      </Select>
    </VStack>
  );
};

export default WorkflowSection;
