import { useDisclosure } from "@chakra-ui/react";

import NodeView from "./NodeView";
import { ProcessNodeProps } from "./type";

const EndCallNode: React.FC<ProcessNodeProps> = ({
  id,
  selected,
  data,
}: ProcessNodeProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <NodeView
        id={id}
        data={data}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        selected={selected}
        nodeType="EndCallNode"
        colorScheme="red"
      />
    </>
  );
};

export default EndCallNode;
