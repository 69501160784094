import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Container,
  Heading,
  VStack,
  Text,
  HStack,
  Badge,
  Spinner,
  useToast,
  Card,
  CardHeader,
  CardBody,
  Grid,
  GridItem,
  Icon,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Divider,
  Tag,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { getCampaign, Campaign, updateCampaign } from "../../api/campaigns";
import { formatDate } from "../../utils/dateUtils";
import {
  FiClock,
  FiCalendar,
  FiPhone,
  FiSettings,
  FiUser,
  FiX,
} from "react-icons/fi";
import { Link } from "react-router-dom";

const formatCallDuration = (duration: number): string => {
  return duration === 0 ? "No Limit" : `${duration}s`;
};

const CampaignDetails: React.FC = () => {
  const { campaignId } = useParams();
  const [campaign, setCampaign] = useState<Campaign | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    fetchCampaignDetails();
  }, [campaignId]);

  const fetchCampaignDetails = async () => {
    if (!campaignId) return;
    try {
      setIsLoading(true);
      const data = await getCampaign(parseInt(campaignId));
      setCampaign(data);
    } catch (error) {
      console.error("Error fetching campaign details:", error);
      toast({
        title: "Error",
        description:
          error instanceof Error
            ? error.message
            : "Failed to fetch campaign details",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const convertUTCToLocalTime = (utcTime: string): string => {
    const [hours, minutes] = utcTime.split(":").map(Number);
    const date = new Date();
    date.setUTCHours(hours, minutes, 0, 0);

    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  const getStatusColor = (status: string) => {
    switch (status.toUpperCase()) {
      case "SCHEDULED":
        return "blue";
      case "ACTIVE":
        return "green";
      case "COMPLETED":
        return "gray";
      case "PAUSED":
        return "yellow";
      default:
        return "gray";
    }
  };

  const canCancelCampaign = (status: string): boolean => {
    const nonCancellableStatuses = ["COMPLETED", "CANCELLED"];
    return !nonCancellableStatuses.includes(status.toUpperCase());
  };

  const handleCancelClick = () => {
    onConfirmOpen();
  };

  const handleConfirmCancel = async () => {
    if (!campaign || !campaignId) return;

    try {
      await updateCampaign(parseInt(campaignId), {
        status: "CANCELLED",
      });

      // Update local state
      setCampaign({
        ...campaign,
        status: "CANCELLED",
      });

      toast({
        title: "Campaign cancelled",
        description: "The campaign has been cancelled successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description:
          error instanceof Error ? error.message : "Failed to cancel campaign",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      onConfirmClose();
    }
  };

  if (isLoading) {
    return (
      <Box
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (!campaign) {
    return <Box>Campaign not found</Box>;
  }

  return (
    <Box>
      <Box mb={8} px={0}>
        <Heading size="lg">Campaign Details</Heading>
      </Box>

      <Container maxW="container.xl" py={4}>
        <VStack spacing={6} align="stretch">
          {/* Header Section */}
          <HStack justify="space-between" align="center">
            <VStack align="start" spacing={1}>
              <Heading size="lg">{campaign.campaign_name}</Heading>
              <Text color="gray.600">{campaign.campaign_description}</Text>
            </VStack>
            <HStack spacing={4}>
              <Badge
                size="lg"
                colorScheme={getStatusColor(campaign.status)}
                px={3}
                py={1}
                borderRadius="full"
                fontSize="md"
              >
                {campaign.status}
              </Badge>
              {canCancelCampaign(campaign.status) && (
                <Button
                  leftIcon={<FiX />}
                  colorScheme="red"
                  variant="outline"
                  size="sm"
                  onClick={handleCancelClick}
                  isDisabled={!canCancelCampaign(campaign.status)}
                >
                  Cancel Campaign
                </Button>
              )}
            </HStack>
          </HStack>

          {/* Key Metrics */}
          <Grid templateColumns="repeat(4, 1fr)" gap={4}>
            <GridItem>
              <Card
                as={Link}
                to={`/dashboard/campaigns/${campaign.campaign_id}/customers`}
                _hover={{ transform: "translateY(-2px)", shadow: "md" }}
                transition="all 0.2s"
                target="_blank"
                rel="noopener noreferrer"
              >
                <CardBody>
                  <Stat>
                    <StatLabel>Total Customers</StatLabel>
                    <StatNumber>
                      {campaign.campaign_customers.length}
                    </StatNumber>
                    <StatHelpText>Click to view details</StatHelpText>
                  </Stat>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem>
              <Card>
                <CardBody>
                  <Stat>
                    <StatLabel>Max Attempts</StatLabel>
                    <StatNumber>{campaign.max_attempts}</StatNumber>
                    <StatHelpText>Per customer</StatHelpText>
                  </Stat>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem>
              <Card>
                <CardBody>
                  <Stat>
                    <StatLabel>Call Duration</StatLabel>
                    <StatNumber>
                      {formatCallDuration(campaign.max_call_duration)}
                    </StatNumber>
                    <StatHelpText>Maximum</StatHelpText>
                  </Stat>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem>
              <Card>
                <CardBody>
                  <Stat>
                    <StatLabel>Channel</StatLabel>
                    <StatNumber>{campaign.communication_channel}</StatNumber>
                    <StatHelpText>Communication type</StatHelpText>
                  </Stat>
                </CardBody>
              </Card>
            </GridItem>
          </Grid>

          {/* Schedule Information */}
          <Card>
            <CardHeader>
              <HStack>
                <Icon as={FiCalendar} />
                <Heading size="md">Schedule</Heading>
              </HStack>
            </CardHeader>
            <CardBody>
              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                <Box>
                  <Text fontWeight="medium" mb={2}>
                    Campaign Timeline
                  </Text>
                  <VStack align="start" spacing={2}>
                    <HStack>
                      <Icon as={FiClock} color="green.500" />
                      <Text>
                        Starts: {formatDate(campaign.campaign_start_datetime)}
                      </Text>
                    </HStack>
                    <HStack>
                      <Icon as={FiClock} color="red.500" />
                      <Text>
                        Ends: {formatDate(campaign.campaign_end_datetime)}
                      </Text>
                    </HStack>
                  </VStack>
                </Box>
                <Box>
                  <Text fontWeight="medium" mb={2}>
                    Call Windows
                  </Text>
                  <VStack align="start" spacing={2}>
                    {campaign.scheduling_rules.allowed_window.map(
                      (window, index) => (
                        <Tag
                          key={index}
                          size="md"
                          variant="subtle"
                          colorScheme="blue"
                        >
                          {convertUTCToLocalTime(window.start_time)} -{" "}
                          {convertUTCToLocalTime(window.end_time)}
                          <Text as="span" fontSize="xs" ml={1}>
                            (Local Time)
                          </Text>
                        </Tag>
                      )
                    )}
                  </VStack>
                </Box>
              </Grid>
            </CardBody>
          </Card>

          {/* Configuration Details */}
          <Card>
            <CardHeader>
              <HStack>
                <Icon as={FiSettings} />
                <Heading size="md">Configuration</Heading>
              </HStack>
            </CardHeader>
            <CardBody>
              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                <VStack align="start" spacing={4}>
                  <Box>
                    <Text fontWeight="medium" mb={2}>
                      Campaign Settings
                    </Text>
                    <VStack align="start" spacing={2}>
                      <Text>Pathway ID: {campaign.pathway_id}</Text>
                      <Text>
                        Voice Config ID: {campaign.voice_configuration_id}
                      </Text>
                      <Text>Scheduling: {campaign.scheduling_option}</Text>
                    </VStack>
                  </Box>
                </VStack>
                <VStack align="start" spacing={4}>
                  <Box>
                    <Text fontWeight="medium" mb={2}>
                      Campaign Numbers
                    </Text>
                    <VStack align="start" spacing={2}>
                      {campaign.communication_numbers.map((number) => (
                        <HStack key={number.number_id}>
                          <Icon as={FiPhone} />
                          <Text>{number.number}</Text>
                          <Badge
                            colorScheme={
                              number.status === "FREE" ? "green" : "red"
                            }
                          >
                            {number.status}
                          </Badge>
                          {number.voice_enabled && (
                            <Badge colorScheme="blue">Voice</Badge>
                          )}
                          {number.sms_enabled && (
                            <Badge colorScheme="purple">SMS</Badge>
                          )}
                        </HStack>
                      ))}
                    </VStack>
                  </Box>
                </VStack>
              </Grid>
            </CardBody>
          </Card>

          {/* Creator Information */}
          <Card>
            <CardBody>
              <HStack spacing={4}>
                <Icon as={FiUser} />
                <VStack align="start" spacing={0}>
                  <Text fontSize="sm" color="gray.600">
                    Created by
                  </Text>
                  <Text>{campaign.user.email}</Text>
                </VStack>
                <Divider orientation="vertical" />
                <VStack align="start" spacing={0}>
                  <Text fontSize="sm" color="gray.600">
                    Created at
                  </Text>
                  <Text>{formatDate(campaign.created_at)}</Text>
                </VStack>
              </HStack>
            </CardBody>
          </Card>
        </VStack>
      </Container>

      <AlertDialog
        isOpen={isConfirmOpen}
        leastDestructiveRef={cancelRef}
        onClose={onConfirmClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Cancel Campaign
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to cancel this campaign? This action cannot
              be undone, and all scheduled calls will be cancelled.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onConfirmClose}>
                No, Keep Campaign
              </Button>
              <Button
                colorScheme="red"
                onClick={handleConfirmCancel}
                ml={3}
                leftIcon={<FiX />}
              >
                Yes, Cancel Campaign
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default CampaignDetails;
