import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Text,
  IconButton,
  Tooltip,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { FaBrain } from "react-icons/fa";
import { Customer } from "../../types/customer";

interface ContactsTableProps {
  customers: Customer[];
  isLoading: boolean;
  onViewMemories: (customer: Customer) => void;
  formatDate: (timestamp: string) => string;
  isLoadingMemories: boolean;
  isSelectedCustomer: Customer | null;
}

export const ContactsTable: React.FC<ContactsTableProps> = ({
  customers,
  isLoading,
  onViewMemories,
  formatDate,
  isLoadingMemories,
  isSelectedCustomer,
}) => {
  if (isLoading) {
    return (
      <Center py={10}>
        <Spinner size="xl" color="teal.500" />
      </Center>
    );
  }

  const renderMemoryButton = (customer: Customer) => {
    if (isLoadingMemories && isSelectedCustomer?.customer_id === customer.customer_id) {
      return (
        <Center>
          <Spinner size="sm" color="teal.500" />
        </Center>
      );
    }

    return (
      <Tooltip label="View Memories" placement="left">
        <IconButton
          aria-label="View memories"
          icon={<FaBrain size={18} />}
          variant="ghost"
          colorScheme="teal"
          onClick={() => onViewMemories(customer)}
          size="md"
          _hover={{
            bg: 'teal.50',
            transform: 'scale(1.05)'
          }}
          _active={{
            bg: 'teal.100',
            transform: 'scale(0.95)'
          }}
          transition="all 0.2s"
        />
      </Tooltip>
    );
  };

  return (
    <Box overflowX="auto" p={4}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Phone</Th>
            <Th>Email</Th>
            <Th>Source</Th>
            <Th>Additional Info</Th>
            <Th>Created At</Th>
            <Th>Memories</Th>
          </Tr>
        </Thead>
        <Tbody>
          {customers.map((customer) => (
            <Tr
              key={customer.customer_id}
              _hover={{ bg: "gray.50" }}
              transition="all 0.2s"
            >
              <Td fontWeight="medium">{customer.customer_name}</Td>
              <Td>{customer.phone_number}</Td>
              <Td>{customer.email || "-"}</Td>
              <Td>
                <Box
                  bg="teal.500"
                  color="white"
                  fontSize="xs"
                  px={2}
                  py={1}
                  borderRadius="lg"
                  fontWeight="medium"
                  display="inline-block"
                >
                  {customer.source.includes("csv") ? "CSV Upload" : "Manual Entry"}
                </Box>
              </Td>
              <Td>
                <Text fontSize="sm">
                  {JSON.stringify(customer.additional_info, null, 2) || "-"}
                </Text>
              </Td>
              <Td>{formatDate(customer.created_at)}</Td>
              <Td>
                {renderMemoryButton(customer)}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}; 