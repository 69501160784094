import axios from "axios";
import { apiWithAuth as api } from "../apiService";

export interface CreateUpdateWorkflow {
  configuration: Record<string, any>;
  workflowName: string;
  workflowDescription: string;
  updatedBy: string;
}

export interface WorkflowConfig {
  organisationId: string;
  workflowId: string;
  workflowName: string;
  workflowDescription: string;
  configuration: Record<string, any>;
  createdAt?: number;
  updatedAt: number;
  createdBy?: string;
  updatedBy: string;
}

interface PresignedUrlResponse {
  uploadUrl: string;
  key: string;
  s3Uri: string;
}

export const getOrganisationWorkflows = async (
  organisationId: string = "1"
) => {
  const response = await api.get(`/organisations/${organisationId}/workflows`);
  return response.data;
};

export const getOrganisationWorkflow = async (
  organisationId: string,
  workflowId: string
) => {
  const response = await api.get(
    `/organisations/${organisationId}/workflows/${workflowId}`
  );
  return response.data;
};

export const createOrganisationWorkflow = async (
  organisationId: string,
  data: CreateUpdateWorkflow
) => {
  const response = await api.post(
    `/organisations/${organisationId}/workflows`,
    data
  );
  return response.data;
};

export const updateOrganisationWorkflow = async (
  organisationId: string,
  workflowId: string,
  data: CreateUpdateWorkflow
) => {
  const response = await api.patch(
    `/organisations/${organisationId}/workflows/${workflowId}`,
    data
  );
  return response.data;
};

export const deleteOrganisationWorkflow = async (
  organisationId: string,
  workflowId: string
) => {
  const response = await api.delete(
    `/organisations/${organisationId}/workflows/${workflowId}`
  );
  return response.data;
};

export const listWorkflowTools = async () => {
  const response = await api.get(`/list-workflow-tools`);
  return response.data;
};

export const getWorkflowConditions = async () => {
  const response = await api.get(`/get-workflow-conditions`);
  return response.data;
};

export const runWorkflow = async (
  config: Record<string, any>,
  input?: string
) => {
  const response = await api.post(`/trigger-workflow`, {
    input,
    config,
  });
  return response.data;
};

export const getPresignedUrls = async (
  organisationId: string,
  files: { name: string; type: string }[]
) => {
  const response = await api.post(
    `/organisations/${organisationId}/workflows/get-presigned-urls`,
    {
      files: files.map((f) => ({ fileName: f.name, contentType: f.type })),
    }
  );
  return response.data as PresignedUrlResponse[];
};

export const uploadFileToS3 = async (url: string, file: File) => {
  const response = await axios.put(url, file, {
    headers: {
      "Content-Type": file.type,
    },
    transformRequest: [(data) => data],
  });
  return response.data;
};

export const processPdfUpload = async (
  organisationId: string,
  s3Uris: string[]
) => {
  const response = await api.post(
    `/organisations/${organisationId}/workflows/process-pdf-upload`,
    { s3Uris }
  );
  return response.data;
};

export const getDocumentUploadStatus = async (
  organisationId: string,
  s3Uri: string
) => {
  const response = await api.post(
    `/organisations/${organisationId}/workflows/get-document-upload-status`,
    { s3Uri }
  );
  return response.data;
};

export const getGoogleAuthenticationOptions = async () => {
  // toodo: update later
  const organisationId = "1";
  const userId = "123";
  const response = await api.get(
    `/organisations/${organisationId}/workflows/get-obfuscated-google-authentication-details/${userId}`
  );
  return response.data;
};
