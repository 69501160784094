import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Text,
  useToast,
  HStack,
  IconButton,
  VStack,
  Spinner,
  Divider,
  Button,
  Container,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
  Center,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import axios from "axios";
import {
  ChevronDownIcon,
  DeleteIcon,
  EditIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import { FaRegClone } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import {
  createOrganisationAgent,
  deleteOrganisationAgent,
  getOrganisationAgent,
  getOrganisationAgents,
} from "../../api/agents";
import { AgentConfig } from "./type";
import { nanoid } from "nanoid";

const Agents: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [agents, setAgents] = useState<AgentConfig[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedGroup, setSelectedGroup] = useState<string>("all");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newPathwayName, setNewPathwayName] = useState("");
  const [newPathwayGroup, setNewPathwayGroup] = useState("ungrouped");
  const [newGroupName, setNewGroupName] = useState("");
  const [availableGroups, setAvailableGroups] = useState<string[]>([]);

  const groupedAgents = React.useMemo(() => {
    const groups: { [key: string]: AgentConfig[] } = {
      ungrouped: [],
    };

    agents.forEach((agent) => {
      if (!agent.group) {
        groups.ungrouped.push(agent);
      } else {
        if (!groups[agent.group]) {
          groups[agent.group] = [];
        }
        groups[agent.group].push(agent);
      }
    });

    return groups;
  }, [agents]);

  useEffect(() => {
    const initialGroups = [
      "all",
      "ungrouped",
      ...Object.keys(groupedAgents).filter((g) => g !== "ungrouped"),
    ];
    setAvailableGroups(initialGroups);
  }, [groupedAgents]);

  const filteredAgents = React.useMemo(() => {
    let filtered = agents.filter((agent) =>
      agent.agentName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (selectedGroup === "all") {
      return filtered;
    }

    if (selectedGroup === "ungrouped") {
      return filtered.filter((agent) => !agent.group);
    }

    return filtered.filter((agent) => agent.group === selectedGroup);
  }, [agents, searchQuery, selectedGroup]);

  useEffect(() => {
    getAgents();
  }, []);

  const handleApiError = (error: unknown) => {
    const errorMessage =
      error instanceof Error
        ? error.message
        : axios.isAxiosError(error) && error.response?.data
        ? error.response.data
        : "Something went wrong. Please try again later.";
    toast({
      title: "Error",
      description: errorMessage,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const getAgents = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getOrganisationAgents("1");
      setAgents(response);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deletePathway = useCallback(async (agentId: string) => {
    if (window.confirm("Are you sure?")) {
      setIsLoading(true);
      try {
        await deleteOrganisationAgent("1", agentId);
        setAgents((prevAgents) =>
          prevAgents.filter((agent) => agent.agentId !== agentId)
        );
      } catch (error) {
        handleApiError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const handleCreatePathway = useCallback(() => {
    onOpen();
  }, [onOpen]);

  const handleSaveNewPathway = async () => {
    // Close the modal
    onClose();

    // Determine the group to use
    const groupToUse =
      newPathwayGroup === "new" && newGroupName.trim() !== ""
        ? newGroupName
        : newPathwayGroup;

    // Add the new group to the available groups if it's a new group
    if (newPathwayGroup === "new" && newGroupName.trim() !== "") {
      setAvailableGroups((prevGroups) => [...prevGroups, newGroupName]);
    }

    // Navigate to the CPWBuilder with the new pathway name and group
    navigate("/dashboard/agent/cpw/new", {
      state: {
        pathwayName: newPathwayName,
        group: groupToUse,
      },
    });
  };

  const handleEditPathway = useCallback(
    (agentId: string) => {
      const agent = agents.filter((agent) => agent.agentId === agentId)[0];
      if (agent.type === "cpw") {
        navigate(`/dashboard/agent/cpw/${agentId}`);
      } else {
        navigate(`/dashboard/agent/mna/${agentId}`);
      }
    },
    [navigate, agents]
  );

  const clonePathway = useCallback(
    async (agentId: string) => {
      setIsLoading(true);
      try {
        const agentToClone = agents.find((agent) => agent.agentId === agentId);
        if (agentToClone == null) {
          return;
        }

        // get the nodes and edges here
        const currentPathwayConfiguration = await getOrganisationAgent(
          "1",
          agentToClone.agentId
        );
        // send a call to create
        const randomId = nanoid();
        const agent: AgentConfig = await createOrganisationAgent("1", {
          ...currentPathwayConfiguration,
          callNumber: "NA",
          agentName: `${agentToClone.agentName} (Clone-${randomId.slice(-3)})`,
        });
        // redirect to the cloned pathway
        navigate(`/dashboard/agent/cpw/${agent.agentId}`);
      } catch (error) {
        handleApiError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [agents]
  );

  return (
    <Container maxW="container.xl" px={4}>
      <Box height={"100%"}>
        <VStack align="stretch" spacing={6}>
          <Text as="h1" fontSize="2xl" fontWeight="bold" py={4}>
            Pathways
          </Text>

          <Flex gap={4} direction={{ base: "column", md: "row" }}>
            <InputGroup maxW="md">
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.400" />
              </InputLeftElement>
              <Input
                bg="white"
                placeholder="Search agents..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                borderRadius="lg"
                _hover={{ borderColor: "teal.300" }}
                _focus={{ borderColor: "teal.500", boxShadow: "outline" }}
              />
            </InputGroup>

            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                bg="white"
                borderRadius="lg"
                _hover={{ borderColor: "teal.300" }}
                _focus={{ borderColor: "teal.500", boxShadow: "outline" }}
              >
                {selectedGroup.charAt(0).toUpperCase() + selectedGroup.slice(1)}
              </MenuButton>
              <MenuList>
                {availableGroups.map((group) => (
                  <MenuItem key={group} onClick={() => setSelectedGroup(group)}>
                    {group.charAt(0).toUpperCase() + group.slice(1)}
                  </MenuItem>
                ))}
                <MenuDivider />
              </MenuList>
            </Menu>

            <Divider orientation="vertical" height="40px" />

            <Button
              colorScheme="teal"
              variant="solid"
              onClick={handleCreatePathway}
              flexShrink={0}
            >
              + Create a Pathway
            </Button>
          </Flex>

          {isLoading ? (
            <Center py={10}>
              <Spinner size="xl" color="teal.500" />
            </Center>
          ) : selectedGroup === "all" ? (
            <VStack align="stretch" spacing={8}>
              {Object.entries(groupedAgents).map(([group, groupAgents]) => {
                const filteredGroupAgents = groupAgents.filter((agent) =>
                  agent.agentName
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
                );

                if (filteredGroupAgents.length === 0) return null;

                return (
                  <Box key={group}>
                    <Text
                      fontSize="lg"
                      fontWeight="medium"
                      color="gray.600"
                      mb={4}
                      pl={2}
                      borderLeft="4px"
                      borderColor="teal.500"
                    >
                      {group === "ungrouped" ? "Ungrouped" : group}
                    </Text>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                      {filteredGroupAgents.map((agent) => (
                        <AgentCard
                          key={agent.agentId}
                          agent={agent}
                          onEdit={handleEditPathway}
                          onClone={clonePathway}
                          onDelete={deletePathway}
                        />
                      ))}
                    </SimpleGrid>
                  </Box>
                );
              })}
            </VStack>
          ) : (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
              {filteredAgents.map((agent) => (
                <AgentCard
                  key={agent.agentId}
                  agent={agent}
                  onEdit={handleEditPathway}
                  onClone={clonePathway}
                  onDelete={deletePathway}
                />
              ))}
            </SimpleGrid>
          )}
        </VStack>
      </Box>

      {/* Modal for creating a new pathway */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Pathway</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Pathway Name</FormLabel>
              <Input
                value={newPathwayName}
                onChange={(e) => setNewPathwayName(e.target.value)}
                placeholder="Enter pathway name"
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Group</FormLabel>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  bg="white"
                  borderRadius="lg"
                  _hover={{ borderColor: "teal.300" }}
                  _focus={{ borderColor: "teal.500", boxShadow: "outline" }}
                >
                  {newPathwayGroup === "new"
                    ? "New Group"
                    : newPathwayGroup.charAt(0).toUpperCase() +
                      newPathwayGroup.slice(1)}
                </MenuButton>
                <MenuList>
                  {availableGroups
                    .filter((group) => group !== "all")
                    .map((group) => (
                      <MenuItem
                        key={group}
                        onClick={() => setNewPathwayGroup(group)}
                      >
                        {group.charAt(0).toUpperCase() + group.slice(1)}
                      </MenuItem>
                    ))}
                  <MenuDivider />
                  <MenuItem onClick={() => setNewPathwayGroup("new")}>
                    + New Group
                  </MenuItem>
                </MenuList>
              </Menu>
              {newPathwayGroup === "new" && (
                <Input
                  mt={2}
                  placeholder="Enter new group name"
                  value={newGroupName}
                  onChange={(e) => setNewGroupName(e.target.value)}
                />
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={handleSaveNewPathway}>
              Create
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  );
};

interface AgentCardProps {
  agent: AgentConfig;
  onEdit: (agentId: string) => void;
  onClone: (agentId: string) => void;
  onDelete: (agentId: string) => void;
}

const AgentCard: React.FC<AgentCardProps> = ({
  agent,
  onEdit,
  onClone,
  onDelete,
}) => (
  <Box
    key={agent.agentId}
    bg="white"
    borderRadius="xl"
    overflow="hidden"
    border="1px solid"
    borderColor="gray.200"
    _hover={{ boxShadow: "md" }}
    transition="all 0.2s"
  >
    <VStack align="stretch" h="full">
      <Box p={5} bg="gray.50">
        <VStack align="start" spacing={2}>
          <HStack justify="space-between" width="100%">
            <Text fontSize="lg" fontWeight="semibold" noOfLines={1}>
              {agent.agentName || agent.agentId}
            </Text>
            <Box
              bg="teal.500"
              color="white"
              fontSize="xs"
              px={2}
              py={1}
              borderRadius="lg"
              fontWeight="medium"
            >
              {agent.type === "cpw" ? "CPW" : "Traditional"}
            </Box>
          </HStack>
          {agent.group && (
            <Text fontSize="sm" color="gray.500">
              Group: {agent.group}
            </Text>
          )}
          {agent.callNumber.replace("NA", "").trim() !== "" && (
            <Text fontSize="sm" color="green.400">
              Connected to - {agent.callNumber}
            </Text>
          )}
        </VStack>
      </Box>

      <Box mt="auto" p={4} borderTop="1px solid" borderColor="gray.100">
        <HStack justify="space-between">
          <Text fontSize="sm" color="gray.600">
            {agent.updatedBy}
          </Text>
          <HStack spacing={2}>
            <IconButton
              size="sm"
              variant="ghost"
              colorScheme="teal"
              icon={<EditIcon />}
              aria-label="Edit Agent"
              onClick={() => onEdit(agent.agentId)}
            />
            <IconButton
              size="sm"
              variant="ghost"
              colorScheme="teal"
              icon={<FaRegClone />}
              aria-label="Clone Agent"
              onClick={() => onClone(agent.agentId)}
            />
            <IconButton
              size="sm"
              variant="ghost"
              colorScheme="red"
              icon={<DeleteIcon />}
              aria-label="Delete Agent"
              onClick={() => onDelete(agent.agentId)}
            />
          </HStack>
        </HStack>
      </Box>
    </VStack>
  </Box>
);

export default Agents;
