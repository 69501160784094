import React, { useCallback, useState } from "react";
import {
  Box,
  Text,
  HStack,
  Icon,
  VStack,
  Input,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { MdNotes, MdEdit, MdDeleteOutline } from "react-icons/md";
import { FaRegClone } from "react-icons/fa";
import { ProcessNodeProps } from "./type";
import { useReactFlow } from "@xyflow/react";
import { nanoid } from "nanoid";

const NotesNode: React.FC<ProcessNodeProps> = ({
  id,
  data,
  selected,
}: ProcessNodeProps) => {
  const { setNodes, deleteElements, addNodes, getNode } = useReactFlow();
  const [isEditingLabel, setIsEditingLabel] = React.useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const onDelete = useCallback(() => {
    deleteElements({ nodes: [{ id }] });
  }, [id, deleteElements]);

  const duplicateNode = useCallback(() => {
    const node: any = getNode(id);
    const position = {
      x: node.position.x + 350,
      y: node.position.y + 0,
    };

    const newNodeId = nanoid();
    const newNodeName = `${node.data.label} (Clone-${newNodeId.slice(-3)})`;

    addNodes({
      ...node,
      selected: false,
      dragging: false,
      id: newNodeId,
      data: { ...node.data, label: newNodeName },
      position,
    });
  }, [id, getNode, addNodes]);

  const handleNotesChange = (value: string) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              notes: value,
            },
          };
        }
        return node;
      })
    );
  };

  const handleLabelChange = (newLabel: string) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              label: newLabel,
            },
          };
        }
        return node;
      })
    );
  };

  return (
    <HStack
      alignItems="flex-start"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        border="1px solid"
        borderColor="yellow.200"
        bgColor="yellow.50"
        borderRadius="xl"
        width={300}
        overflow="clip"
        boxShadow={selected ? "md" : "sm"}
        transition="box-shadow 0.2s"
      >
        <VStack align="stretch" spacing={0}>
          <HStack p={4} spacing={3}>
            <Box bg="yellow.100" p={3} borderRadius="lg" color="yellow.700">
              <Icon as={MdNotes} boxSize={6} />
            </Box>
            <VStack align="start" spacing={0} flex={1}>
              <Text color="gray.500" fontSize="sm" fontWeight="medium">
                Notes
              </Text>
              {isEditingLabel ? (
                <Input
                  value={data.label || ""}
                  onChange={(e) => handleLabelChange(e.target.value)}
                  onBlur={() => setIsEditingLabel(false)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setIsEditingLabel(false);
                    }
                  }}
                  size="lg"
                  fontWeight="medium"
                  autoFocus
                />
              ) : (
                <HStack
                  spacing={1}
                  onClick={() => selected && setIsEditingLabel(true)}
                  cursor={selected ? "pointer" : "default"}
                  position="relative"
                  role="group"
                >
                  <Text fontWeight="medium" fontSize="lg">
                    {data.label || "Quick Notes"}
                  </Text>
                  {selected && (
                    <Icon
                      as={MdEdit}
                      opacity={0}
                      _groupHover={{ opacity: 0.5 }}
                      fontSize="sm"
                    />
                  )}
                </HStack>
              )}
            </VStack>
          </HStack>

          <Box p={4} bg="white">
            <Box
              as="textarea"
              value={data.notes || ""}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                handleNotesChange(e.target.value)
              }
              placeholder="Enter your notes here..."
              minH="150px"
              w="100%"
              border="none"
              _focus={{ outline: "none" }}
              style={{
                resize: "none",
                background: "transparent",
              }}
              readOnly={!selected}
              rows={data.notes?.split("\n").length || 1}
              onMouseDown={(e: React.MouseEvent<HTMLTextAreaElement>) => {
                e.stopPropagation();
              }}
              onClick={(e: React.MouseEvent<HTMLTextAreaElement>) => {
                e.stopPropagation();
              }}
            />
          </Box>
        </VStack>
      </Box>

      {(selected || isHovered) && (
        <Box
          position="absolute"
          right="-48px"
          top={4}
          bg="yellow.50"
          borderRadius="lg"
          p={1}
        >
          <VStack spacing={2}>
            <Tooltip label="Delete Node" placement="right">
              <IconButton
                variant="ghost"
                colorScheme="red"
                aria-label="Delete Node"
                size="sm"
                onClick={onDelete}
                icon={<MdDeleteOutline />}
              />
            </Tooltip>
            <Tooltip label="Clone Node" placement="right">
              <IconButton
                variant="ghost"
                colorScheme="blue"
                aria-label="Clone Node"
                size="sm"
                onClick={duplicateNode}
                icon={<FaRegClone />}
              />
            </Tooltip>
          </VStack>
        </Box>
      )}
    </HStack>
  );
};

export default NotesNode;
