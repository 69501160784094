import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Flex,
  Input,
  Button,
  VStack,
  Container,
  HStack,
} from "@chakra-ui/react";
import { useOutletContext } from "react-router-dom";

import MDEditor from "@uiw/react-md-editor";

import {
  AIType,
  getChatMessages,
  sendNewChatMessage,
  sendConversationChatMessage,
} from "../../api/chat";

import { useParams, useNavigate } from "react-router-dom";

interface Message {
  id: number;
  content: string;
  sender: "user" | "assistant";
}

interface ChatOutletContext {
  aiPower: string;
  llmType: string;
  context: string;
}

const ChatPage: React.FC = () => {
  const { conversationId } = useParams();
  const navigate = useNavigate();

  const { aiPower, llmType, context } = useOutletContext<ChatOutletContext>();

  console.log(aiPower, llmType, context);

  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState("");

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const handleSendMessage = async () => {
    if (inputMessage.trim() !== "") {
      setIsLoading(true);
      const newUserMessage: Message = {
        id: Date.now(),
        content: inputMessage,
        sender: "user",
      };
      setMessages((prevMessages) => [...prevMessages, newUserMessage]);
      setInputMessage("");

      try {
        const chatRequest = {
          history: messages.map((msg) => ({
            sender: msg.sender,
            content: msg.content,
          })),
          query: inputMessage,
          context: context,
          aiType: llmType === "openai" ? AIType.GPT : AIType.CLAUDE,
          aiPower: aiPower,
        };

        let redirect = false;
        let response;
        if (conversationId !== undefined) {
          response = await sendConversationChatMessage(
            conversationId,
            chatRequest
          );
        } else {
          response = await sendNewChatMessage(chatRequest);
          redirect = true;
        }

        const botResponse: Message = {
          id: Date.now() + 1,
          content: response.response,
          sender: "assistant",
        };
        setMessages((prevMessages) => [...prevMessages, botResponse]);
        console.log("response", response);
        console.log("redirect", redirect);
        if (redirect) {
          navigate(`/dashboard/chats/${response.conversationId}`);
        }
      } catch (error) {
        console.error("Error sending message:", error);
        // Handle error (e.g., show an error message to the user)
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (conversationId !== undefined) {
      getConversationMessages();
    }
  }, [conversationId]);

  const getConversationMessages = async () => {
    setIsLoading(true);
    try {
      const chatMessages = await getChatMessages(conversationId!);
      const formattedMessages = chatMessages.map((chatMessage: any) => ({
        id: chatMessage.message.id,
        content: chatMessage.message.content,
        sender: chatMessage.message.sender,
      }));
      setMessages(formattedMessages);
      console.log(formattedMessages);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  console.log("conversationId", conversationId);

  return (
    <Flex height="100%" flexDirection="column">
      <Box flex={1} overflowY="auto" p={6} pt={16} bg="gray.50">
        <VStack spacing={4} align="stretch">
          {messages.map((message) => (
            <Flex
              key={message.id}
              justify={message.sender === "user" ? "flex-end" : "flex-start"}
            >
              <Box
                data-color-mode="light"
                maxW="70%"
                bg={message.sender === "user" ? "teal.500" : "white"}
                borderRadius="xl"
                px={4}
                py={3}
                boxShadow="sm"
                border="1px solid"
                borderColor={
                  message.sender === "user" ? "transparent" : "gray.200"
                }
              >
                <MDEditor.Markdown
                  source={message.content}
                  style={{
                    backgroundColor: "transparent",
                    color: message.sender === "user" ? "white" : "black",
                  }}
                />
              </Box>
            </Flex>
          ))}
          <div ref={messagesEndRef} />
        </VStack>
      </Box>

      <Box p={4} bg="white" borderTop="1px solid" borderColor="gray.200">
        <Container maxW="container.xl">
          <HStack spacing={3}>
            <Input
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Type a message..."
              bg="white"
              borderRadius="lg"
              _hover={{ borderColor: "teal.300" }}
              _focus={{ borderColor: "teal.500", boxShadow: "outline" }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
            />
            <Button
              onClick={handleSendMessage}
              colorScheme="teal"
              isLoading={isLoading}
              isDisabled={isLoading}
              px={8}
            >
              Send
            </Button>
          </HStack>
        </Container>
      </Box>
    </Flex>
  );
};

export default ChatPage;
