import React, { useCallback, useState } from "react";
import {
  Box,
  Text,
  HStack,
  Icon,
  IconButton,
  VStack,
  Tooltip,
} from "@chakra-ui/react";
import { Handle, Position, useReactFlow } from "@xyflow/react";

import { CiPlay1 } from "react-icons/ci";
import { FaRegClone } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";

import DrawerNodeForm from "./DrawerNodeForm";
import { NodeViewProps } from "./type";
import { availableNodeTypes } from "../../pages/CPWBuilder/nodeTypes";
import { nanoid } from "nanoid";

const getNodeIcon = (nodeType: string) => {
  const node = availableNodeTypes.filter((node) => node.type === nodeType)[0];
  return node.icon;
};

const NodeView: React.FC<NodeViewProps> = ({
  id,
  data,
  isOpen,
  onClose,
  onOpen,
  nodeType = "normal",
  selected = false,
  colorScheme = "blue",
}: NodeViewProps) => {
  const { deleteElements, addNodes, getNode } = useReactFlow();
  const [isHovered, setIsHovered] = useState(false);

  const onDelete = useCallback(() => {
    deleteElements({ nodes: [{ id }] });
  }, [id, deleteElements]);

  const duplicateNode = useCallback(() => {
    const node: any = getNode(id);
    const position = {
      x: node.position.x + 350,
      y: node.position.y + 0,
    };

    const newNodeId = nanoid();
    const newNodeName = `${node.data.label} (Clone-${newNodeId.slice(-3)})`;

    addNodes({
      ...node,
      selected: false,
      dragging: false,
      id: newNodeId,
      data: { ...node.data, label: newNodeName },
      position,
    });
  }, [id, getNode, addNodes]);

  const icon = data.isPrimaryNode ? CiPlay1 : getNodeIcon(data.nodeInfo.type);
  const nodeText = data.isPrimaryNode ? "Start" : data.label;
  const nodeTypeName = data.nodeInfo.name;
  return (
    <>
      <HStack
        alignItems="flex-start"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Box
          border="1px solid"
          borderColor={`${colorScheme}.200`}
          bgColor={`${colorScheme}.50`}
          borderRadius="xl"
          width={300}
          overflow="clip"
          boxShadow="sm"
          _hover={{ boxShadow: "md" }}
          transition="box-shadow 0.2s"
          onDoubleClick={onOpen}
          flex={1}
        >
          <VStack align="stretch" spacing={0} maxH="200px">
            <HStack p={4} spacing={3}>
              <Box
                bg={`${colorScheme}.100`}
                p={3}
                borderRadius="lg"
                color={`${colorScheme}.700`}
              >
                <Icon as={icon} boxSize={6} />
              </Box>
              <VStack align="start" spacing={0} flex={1}>
                <Text color="gray.500" fontSize="sm" fontWeight="medium">
                  {nodeTypeName}
                </Text>
                <Text fontWeight="medium" fontSize="lg">
                  {nodeText}
                </Text>
              </VStack>
            </HStack>

            <Box
              p={4}
              bg="white"
              h="100px"
              position="relative"
              _after={{
                content: '""',
                position: "absolute",
                bottom: 0,
                right: 0,
                left: 0,
                height: "20px",
                background: "linear-gradient(transparent, white)",
              }}
            >
              <Text overflow="hidden">{data.prompt}</Text>
            </Box>
          </VStack>

          <>
            <Handle
              type="target"
              position={Position.Top}
              style={{
                width: "8px",
                height: "8px",
                background: `var(--chakra-colors-${colorScheme}-100)`,
                border: `2px solid var(--chakra-colors-${colorScheme}-500)`,
              }}
            />
            <Handle
              type="source"
              position={Position.Bottom}
              style={{
                width: "8px",
                height: "8px",
                background: `var(--chakra-colors-${colorScheme}-100)`,
                border: `2px solid var(--chakra-colors-${colorScheme}-500)`,
              }}
            />
          </>
        </Box>

        {(selected || isHovered) && (
          <Box
            position="absolute"
            right="-48px"
            top={4}
            bg={`${colorScheme}.50`}
            borderRadius="lg"
            p={1}
          >
            <VStack spacing={2}>
              <Tooltip label="Delete Node" placement="right">
                <IconButton
                  variant="ghost"
                  colorScheme="red"
                  aria-label="Delete Node"
                  size="sm"
                  onClick={onDelete}
                  icon={<MdDeleteOutline />}
                />
              </Tooltip>
              <Tooltip label="Clone Node" placement="right">
                <IconButton
                  variant="ghost"
                  colorScheme="blue"
                  aria-label="Clone Node"
                  size="sm"
                  onClick={duplicateNode}
                  icon={<FaRegClone />}
                />
              </Tooltip>
            </VStack>
          </Box>
        )}
      </HStack>

      <DrawerNodeForm
        id={id}
        isOpen={isOpen}
        onClose={onClose}
        data={data}
        nodeType={nodeType}
      />
    </>
  );
};

export default NodeView;
