import { apiWithAuth } from "../apiService";

interface Memory {
  created_at: string;
  hash: string;
  id: string;
  memory: string;
  metadata: any | null;
  updated_at: string | null;
  user_id: string;
}

interface ApiResponse<T> {
  success: boolean;
  message: string;
  data: T;
}

interface EditMemoryPayload {
  message: string;
}

export const getMemories = async (userId: string): Promise<Memory[]> => {
  try {
    const response = await apiWithAuth.get<ApiResponse<Memory[]>>(`/memories/${userId}`);

    if (!response.data.success) {
      throw new Error(response.data.message || "Failed to fetch memories");
    }

    return response.data.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message || error.message || "Failed to fetch memories");
  }
};

export const editMemory = async (memoryId: string, data: EditMemoryPayload): Promise<void> => {
  try {
    const response = await apiWithAuth.patch<ApiResponse<string>>(`/memories/${memoryId}`, data);

    if (!response.data.success) {
      throw new Error(response.data.message || "Failed to update memory");
    }
  } catch (error: any) {
    throw new Error(error.response?.data?.message || error.message || "Failed to update memory");
  }
};

export const deleteMemory = async (memoryId: string): Promise<void> => {
  try {
    const response = await apiWithAuth.delete<ApiResponse<string>>(`/memories/${memoryId}`);

    if (!response.data.success) {
      throw new Error(response.data.message || "Failed to delete memory");
    }
  } catch (error: any) {
    throw new Error(error.response?.data?.message || error.message || "Failed to delete memory");
  }
};


