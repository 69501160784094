import React, { useCallback, useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Text,
  VStack,
  Switch,
  FormControl,
  FormLabel,
  Input,
  HStack,
  useToast,
} from "@chakra-ui/react";
import {
  ReactFlow,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
  addEdge,
  BackgroundVariant,
  Node,
  Edge,
  OnConnect,
  OnNodesChange,
  OnEdgesChange,
  NodeTypes,
  EdgeTypes,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { AddIcon } from "@chakra-ui/icons";

import WorkflowEdge from "./components/WorkflowEdge/WorkflowEdge";
import UndoRedoControls from "../../components/UndoRedoControls";
import BaseNode from "./components/WorkflowNode/BaseNode";
import { areNodesEqual } from "./helpers/WorkflowBuilder.helper";
import {
  listWorkflowTools,
  runWorkflow,
  createOrganisationWorkflow,
  updateOrganisationWorkflow,
  getOrganisationWorkflow,
  WorkflowConfig,
} from "../../api/workflows";
import ToolsMenu from "./components/ToolsMenu/ToolsMenu";
import { WorkflowTool } from "./interfaces/interfaces";
import { nanoid } from "nanoid";
import { MdOutlineWifiCalling3 } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";

const WorkflowBuilder: React.FC = () => {
  const { workflowId } = useParams<{ workflowId: string | undefined }>();
  const navigate = useNavigate();
  const toast = useToast();
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [history, setHistory] = useState<{ nodes: Node[]; edges: Edge[] }[]>([
    { nodes: [], edges: [] },
  ]);
  const [historyIndex, setHistoryIndex] = useState(0);
  const [output, setOutput] = useState<string>("");
  const [workflowName, setWorkflowName] = useState<string>("");
  const [workflowDescription, setWorkflowDescription] = useState<string>("");
  const [isSaving, setIsSaving] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [showIntermediaryOutput, setShowIntermediaryOutput] = useState(false);
  const [workflowTools, setWorkflowTools] = useState<Record<string, any>>({});
  const [isToolsMenuOpen, setIsToolsMenuOpen] = useState(false);

  const nodeTypes: NodeTypes = useMemo(() => ({ BaseNode }), []);
  const edgeTypes: EdgeTypes = useMemo(() => ({ WorkflowEdge }), []);

  useEffect(() => {
    console.log(nodes);
    console.log(edges);
  }, [nodes, edges]);

  useEffect(() => {
    listWorkflowTools().then((tools: any) => {
      setWorkflowTools(tools.data);
    });

    if (workflowId) {
      getOrganisationWorkflow("1", workflowId).then((workflow: any) => {
        setNodes(workflow.configuration.nodes);
        setEdges(workflow.configuration.edges);
        setWorkflowName(workflow.workflowName);
        setWorkflowDescription(workflow.workflowDescription);
      });
    }
  }, []);

  const updateHistory = (newNodes: Node[], newEdges: Edge[]) => {
    const newHistory = [
      ...history.slice(0, historyIndex + 1),
      { nodes: newNodes, edges: newEdges },
    ];
    if (newHistory.length > 30) newHistory.shift();
    setHistory(newHistory);
    setHistoryIndex(newHistory.length - 1);
  };

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      const updatedNodes = applyNodeChanges(changes, nodes);
      if (
        updatedNodes.length !== nodes.length ||
        !nodes.every((node, index) => areNodesEqual(node, updatedNodes[index]))
      ) {
        updateHistory(updatedNodes, edges);
      }
      setNodes(updatedNodes);
    },
    [nodes, edges]
  );

  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => {
      const updatedEdges = applyEdgeChanges(changes, edges);
      if (
        updatedEdges.length !== edges.length ||
        !edges.every(
          (edge, index) =>
            JSON.stringify(edge) === JSON.stringify(updatedEdges[index])
        )
      ) {
        updateHistory(nodes, updatedEdges);
      }
      setEdges(updatedEdges);
    },
    [nodes, edges]
  );

  const undo = () => {
    if (historyIndex > 0) {
      setHistoryIndex(historyIndex - 1);
      setNodes(history[historyIndex - 1].nodes);
      setEdges(history[historyIndex - 1].edges);
    }
  };

  const redo = () => {
    if (historyIndex < history.length - 1) {
      setHistoryIndex(historyIndex + 1);
      setNodes(history[historyIndex + 1].nodes);
      setEdges(history[historyIndex + 1].edges);
    }
  };

  const onConnect: OnConnect = useCallback((params) => {
    console.log(params);
    setEdges((eds) =>
      addEdge(
        {
          ...params,
          type: "WorkflowEdge",
          data: {
            handleAddNode: handleAddNode,
          },
          animated: true,
          style: { stroke: "blue" },
        },
        eds
      )
    );
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);
      if (isMac ? event.metaKey : event.ctrlKey) {
        if (event.key === "z") {
          event.preventDefault();
          undo();
        } else if (event.key === "y") {
          event.preventDefault();
          redo();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [undo, redo]);

  const handleSaveWorkflow = useCallback(() => {
    setIsSaving(true);
    if (nodes.length === 0) {
      return;
    }
    const configuration = {
      nodes,
      edges,
    };

    const workflowData = {
      configuration,
      workflowName,
      workflowDescription,
      updatedBy: "test@test.com",
    };

    if (workflowId) {
      updateOrganisationWorkflow("1", workflowId, workflowData)
        .then(() => {
          toast({
            title: "Workflow saved",
            description: "Workflow saved successfully",
            status: "success",
          });
        })
        .catch((err: any) => {
          toast({
            title: "Error saving workflow",
            description: err.message,
            status: "error",
          });
        });
    } else {
      createOrganisationWorkflow("1", workflowData)
        .then((workflow: WorkflowConfig) => {
          toast({
            title: "Workflow saved",
            description: "Workflow saved successfully",
            status: "success",
          });
          navigate(`/dashboard/workflow/${workflow.workflowId}`);
        })
        .catch((err: any) => {
          toast({
            title: "Error saving workflow",
            description: err.message,
            status: "error",
          });
        });
    }
    setIsSaving(false);
  }, [nodes, edges, workflowId, workflowName, workflowDescription]);

  const handleRunWorkflow = useCallback(() => {
    setIsRunning(true);
    setOutput("");
    const config = {
      nodes,
      edges,
    };
    runWorkflow(config)
      .then((res: any) => {
        const result = res.data.result;
        const formattedOutput =
          typeof result === "object"
            ? JSON.stringify(result, null, 2)
            : String(result);
        setOutput(formattedOutput);
      })
      .catch((err: any) => {
        toast({
          title: "Error running workflow",
          description: err.message,
          status: "error",
        });
      })
      .finally(() => {
        setIsRunning(false);
      });
  }, [showIntermediaryOutput, nodes, edges]);

  const handleAddNode = useCallback(
    (tool: WorkflowTool) => {
      console.log(tool);
      const nodeId = nanoid();
      const newNode: Node = {
        id: nodeId,
        data: {
          label:
            tool.category !== "BaseTool"
              ? tool.name
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")
              : tool.name,
          isPrimaryNode: nodes.length === 0,
          nodeInfo: {
            name: tool.name,
            icon: MdOutlineWifiCalling3,
            schema: tool,
            user_provided_variables: {},
          },
          user_provided_variables: {},
          conditionGroup: {
            conditions: [],
            logicalOperator: "AND",
          },
          inputVariables: [],
          type: tool.category,
        },
        type: "BaseNode",
        position: {
          x: nodes.length > 0 ? nodes[nodes.length - 1].position.x : 100,
          y: nodes.length > 0 ? nodes[nodes.length - 1].position.y + 100 : 100,
        },
      };

      setNodes((nds) => nds.concat(newNode));
      updateHistory([...nodes, newNode], edges);
    },
    [nodes]
  );

  const handleToolSelect = useCallback(
    (tool: WorkflowTool) => {
      setIsToolsMenuOpen(false);
      handleAddNode(tool);
    },
    [handleAddNode]
  );

  return (
    <Box height="calc(100vh - 56px)" position="relative">
      <Button
        leftIcon={<AddIcon />}
        colorScheme="blue"
        position="absolute"
        top={4}
        left={4}
        zIndex={10}
        onClick={() => setIsToolsMenuOpen(true)}
        size="md"
        boxShadow="sm"
        _hover={{ boxShadow: "md" }}
        transition="box-shadow 0.2s"
      >
        Add Node
      </Button>

      <ToolsMenu
        tools={workflowTools}
        onToolSelect={handleToolSelect}
        isOpen={isToolsMenuOpen}
        onClose={() => setIsToolsMenuOpen(false)}
      />

      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        snapToGrid={false}
        proOptions={{ hideAttribution: true }}
        style={{ background: 'white' }}
      >
        <Background
          color="#a4a4b0"
          variant={BackgroundVariant.Dots}
          gap={20}
          size={2}
        />
        <UndoRedoControls
          undo={undo}
          redo={redo}
          canUndo={historyIndex > 0}
          canRedo={historyIndex < history.length - 1}
        />

        <Box
          position="absolute"
          top={4}
          right={4}
          bg="white"
          borderRadius="xl"
          boxShadow="sm"
          p={4}
          width="300px"
          zIndex={10}
          border="1px solid"
          borderColor="gray.200"
          _hover={{ boxShadow: "md" }}
          transition="box-shadow 0.2s"
        >
          <VStack spacing={4} align="stretch">
            <FormControl>
              <FormLabel fontSize="sm" mb={1}>
                Workflow Name
              </FormLabel>
              <Input
                size="sm"
                value={workflowName}
                onChange={(e) => setWorkflowName(e.target.value)}
                placeholder="Enter workflow name"
                bg="gray.50"
              />
            </FormControl>

            <FormControl>
              <FormLabel fontSize="sm" mb={1}>
                Description
              </FormLabel>
              <Input
                size="sm"
                value={workflowDescription}
                onChange={(e) => setWorkflowDescription(e.target.value)}
                placeholder="Enter workflow description"
                bg="gray.50"
              />
            </FormControl>

            <HStack spacing={2}>
              <Button
                colorScheme="blue"
                size="sm"
                onClick={handleSaveWorkflow}
                isLoading={isSaving}
                loadingText="Saving..."
                flex={1}
                boxShadow="sm"
                _hover={{ boxShadow: "md" }}
                transition="box-shadow 0.2s"
              >
                Save
              </Button>
              <Button
                colorScheme="blue"
                size="sm"
                onClick={handleRunWorkflow}
                isLoading={isRunning}
                loadingText="Running..."
                flex={1}
                boxShadow="sm"
                _hover={{ boxShadow: "md" }}
                transition="box-shadow 0.2s"
              >
                Run
              </Button>
            </HStack>

            <FormControl
              display="flex"
              alignItems="center"
              bg="gray.50"
              p={2}
              borderRadius="lg"
              size="sm"
            >
              <FormLabel
                htmlFor="show-intermediary"
                mb={0}
                fontSize="sm"
                fontWeight="medium"
              >
                Show intermediary outputs
              </FormLabel>
              <Switch
                id="show-intermediary"
                isChecked={showIntermediaryOutput}
                onChange={(e) => setShowIntermediaryOutput(e.target.checked)}
                size="sm"
              />
            </FormControl>

            <Box
              bg="gray.50"
              p={3}
              borderRadius="lg"
              minHeight="120px"
              maxHeight="200px"
              overflowY="auto"
              border="1px"
              borderColor="gray.200"
              sx={{
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "transparent",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "gray.300",
                  borderRadius: "full",
                },
              }}
            >
              <Text
                as="pre"
                fontSize="xs"
                whiteSpace="pre-wrap"
                color={output ? "gray.800" : "gray.500"}
                fontWeight="medium"
              >
                {output || "Workflow output will appear here..."}
              </Text>
            </Box>
          </VStack>
        </Box>
      </ReactFlow>
    </Box>
  );
};

export default WorkflowBuilder;
