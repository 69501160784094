import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Text,
  useToast,
  HStack,
  IconButton,
  VStack,
  Spinner,
  Divider,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Container,
  Center,
  SimpleGrid,
} from "@chakra-ui/react";
import axios from "axios";
import { DeleteIcon, EditIcon, SearchIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

import {
  deleteVoiceConfiguration,
  getVoiceConfigurations,
} from "../../api/voice";
import { VoiceListConfig } from "./type";

const Voices: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [voices, setVoices] = useState<VoiceListConfig[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getVoices();
  }, []);

  const handleApiError = (error: unknown) => {
    const errorMessage =
      error instanceof Error
        ? error.message
        : axios.isAxiosError(error) && error.response?.data
        ? error.response.data
        : "Something went wrong. Please try again later.";
    toast({
      title: "Error",
      description: errorMessage,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const getVoices = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getVoiceConfigurations();
      setVoices(response);
      console.log(response);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const deleteVoice = useCallback(async (voiceId: string) => {
    if (window.confirm("Are you sure?")) {
      setIsLoading(true);
      try {
        await deleteVoiceConfiguration(voiceId);
        getVoices();
      } catch (error) {
        handleApiError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const handleCreateAgent = useCallback(() => {
    navigate("/dashboard/voice/new");
  }, [navigate]);

  const handleEditAgent = useCallback(
    (agentId: string) => {
      navigate(`/dashboard/voice/edit/${agentId}`);
    },
    [navigate]
  );

  const filteredVoices = voices.filter((voice) =>
    (voice.agent_name || voice.agent_id)
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <Container maxW="container.xl" px={4}>
      <Box height={"100%"}>
        <VStack align="stretch" spacing={6}>
          <Text as="h1" fontSize="2xl" fontWeight="bold" py={4}>
            Voices
          </Text>

          <HStack spacing={4}>
            <InputGroup maxW="md">
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.400" />
              </InputLeftElement>
              <Input
                bg="white"
                placeholder="Search voices..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                borderRadius="lg"
                _hover={{ borderColor: "teal.300" }}
                _focus={{ borderColor: "teal.500", boxShadow: "outline" }}
              />
            </InputGroup>

            <Divider orientation="vertical" height="40px" />

            <Button
              colorScheme="teal"
              variant="solid"
              onClick={handleCreateAgent}
              flexShrink={0}
            >
              + Voice Configuration
            </Button>
          </HStack>

          {isLoading ? (
            <Center py={10}>
              <Spinner size="xl" color="teal.500" />
            </Center>
          ) : (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
              {filteredVoices.map((voice) => (
                <Box
                  key={voice.agent_id}
                  bg="white"
                  borderRadius="xl"
                  overflow="hidden"
                  border="1px solid"
                  borderColor="gray.200"
                  _hover={{ boxShadow: "md" }}
                  transition="all 0.2s"
                >
                  <VStack align="stretch" h="full">
                    <Box p={5} bg="gray.50">
                      <VStack align="start" spacing={2}>
                        <Text fontSize="lg" fontWeight="semibold" noOfLines={1}>
                          {voice.agent_name || voice.agent_id}
                        </Text>
                      </VStack>
                    </Box>

                    <Box
                      mt="auto"
                      p={4}
                      borderTop="1px solid"
                      borderColor="gray.100"
                    >
                      <HStack justify="space-between">
                        <Text fontSize="sm" color="gray.600">
                          {voice.last_modification_timestamp}
                        </Text>
                        <HStack spacing={2}>
                          <IconButton
                            size="sm"
                            variant="ghost"
                            colorScheme="teal"
                            icon={<EditIcon />}
                            aria-label="Edit Voice"
                            onClick={() => handleEditAgent(voice.agent_id)}
                          />
                          <IconButton
                            size="sm"
                            variant="ghost"
                            colorScheme="red"
                            icon={<DeleteIcon />}
                            aria-label="Delete Voice"
                            onClick={() => deleteVoice(voice.agent_id)}
                          />
                        </HStack>
                      </HStack>
                    </Box>
                  </VStack>
                </Box>
              ))}
            </SimpleGrid>
          )}
        </VStack>
      </Box>
    </Container>
  );
};

export default Voices;
