import React, { useState } from "react";
import {
  Flex,
  Input,
  Button,
  Text,
  VStack,
  Container,
  Box,
  Icon,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FiMessageSquare } from "react-icons/fi";
import { AIType, sendNewChatMessage } from "../../api/chat";

interface ChatNewProps {
  aiPower: string;
  llmType: string;
  context: string;
}

const ChatNew: React.FC<ChatNewProps> = ({ aiPower, llmType, context }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [inputMessage, setInputMessage] = useState("");

  const handleSendMessage = async () => {
    if (inputMessage.trim() !== "") {
      try {
        setIsLoading(true);
        const chatRequest = {
          history: [],
          query: inputMessage,
          context: context,
          aiType: llmType === "openai" ? AIType.GPT : AIType.CLAUDE,
          aiPower: aiPower,
        };

        const response = await sendNewChatMessage(chatRequest);
        navigate(`/dashboard/chats/${response.conversationId}`);
      } catch (error) {
        console.error("Error sending message:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Flex height="100%" flexDirection="column" pt={16} bg="gray.50">
      <Container maxW="container.md">
        <VStack spacing={8} align="center">
          <Box
            bg="white"
            p={8}
            borderRadius="xl"
            boxShadow="sm"
            border="1px solid"
            borderColor="gray.200"
            width="100%"
          >
            <VStack spacing={6} align="center">
              <Icon as={FiMessageSquare} boxSize={12} color="teal.500" />
              <Text fontSize="2xl" fontWeight="bold" color="gray.700">
                Start a New Conversation
              </Text>
              <Text fontSize="md" color="gray.500" textAlign="center">
                Ask me anything! I'm here to help with your questions and tasks.
              </Text>
              <VStack width="100%" spacing={4}>
                <Input
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  placeholder="Type your message here..."
                  size="lg"
                  bg="white"
                  borderRadius="lg"
                  _hover={{ borderColor: "teal.300" }}
                  _focus={{ borderColor: "teal.500", boxShadow: "outline" }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSendMessage();
                    }
                  }}
                />
                <Button
                  onClick={handleSendMessage}
                  colorScheme="teal"
                  isLoading={isLoading}
                  isDisabled={isLoading || !inputMessage.trim()}
                  size="lg"
                  width="full"
                  loadingText="Starting conversation..."
                >
                  Start Conversation
                </Button>
              </VStack>
            </VStack>
          </Box>
        </VStack>
      </Container>
    </Flex>
  );
};

export default ChatNew;
