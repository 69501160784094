import React from "react";
import {
  FormControl,
  FormLabel,
  VStack,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  IconButton,
  Portal,
} from "@chakra-ui/react";
import { ChevronDownIcon, DeleteIcon, AddIcon } from "@chakra-ui/icons";

interface InputVariable {
  name: string;
  type: string;
  value: string;
}

interface InputNodeConfigProps {
  inputVariables: InputVariable[];
  setInputVariables: (variables: InputVariable[]) => void;
}

const InputNodeConfig: React.FC<InputNodeConfigProps> = ({
  inputVariables,
  setInputVariables,
}) => {
  return (
    <FormControl>
      <FormLabel>Input Variables</FormLabel>
      <VStack spacing={4} align="stretch">
        {inputVariables.map((variable, index) => (
          <HStack key={index} spacing={2} align="start">
            <VStack flex={1} spacing={2}>
              <Input
                placeholder="Variable name"
                value={variable.name}
                onChange={(e) => {
                  const newVariables = [...inputVariables];
                  newVariables[index] = {
                    ...variable,
                    name: e.target.value,
                  };
                  setInputVariables(newVariables);
                }}
              />
              <Input
                placeholder="Default value"
                value={variable.value}
                type={variable.type === "number" ? "number" : "text"}
                onChange={(e) => {
                  const newVariables = [...inputVariables];
                  let newValue = e.target.value;

                  // For number type, only update if it's a valid number or empty
                  if (variable.type === "number") {
                    if (newValue === "" || !isNaN(Number(newValue))) {
                      newVariables[index] = {
                        ...variable,
                        value: newValue,
                      };
                      setInputVariables(newVariables);
                    }
                  } else {
                    // For string type, accept any value
                    newVariables[index] = {
                      ...variable,
                      value: newValue,
                    };
                    setInputVariables(newVariables);
                  }
                }}
              />

              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  width="full"
                  textAlign="left"
                  variant="outline"
                >
                  {variable.type || "Select type"}
                </MenuButton>
                <Portal>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        const newVariables = [...inputVariables];
                        newVariables[index] = {
                          ...variable,
                          type: "string",
                        };
                        setInputVariables(newVariables);
                      }}
                    >
                      String
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        const newVariables = [...inputVariables];
                        newVariables[index] = {
                          ...variable,
                          type: "number",
                        };
                        setInputVariables(newVariables);
                      }}
                    >
                      Number
                    </MenuItem>
                  </MenuList>
                </Portal>
              </Menu>
            </VStack>

            <IconButton
              aria-label="Remove variable"
              icon={<DeleteIcon />}
              colorScheme="red"
              variant="ghost"
              size="sm"
              isDisabled={inputVariables.length === 1}
              onClick={() => {
                const newVariables = inputVariables.filter(
                  (_, i) => i !== index
                );
                setInputVariables(newVariables);
              }}
            />
          </HStack>
        ))}

        <Button
          leftIcon={<AddIcon />}
          size="sm"
          onClick={() => {
            setInputVariables([
              ...inputVariables,
              { name: "", type: "string", value: "" },
            ]);
          }}
        >
          Add Variable
        </Button>
      </VStack>
    </FormControl>
  );
};

export default InputNodeConfig;
