import React from "react";
import { Controls, ControlButton } from "@xyflow/react";
import { FaUndo, FaRedo } from "react-icons/fa";

interface UndoRedoControlsProps {
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
}

const UndoRedoControls: React.FC<UndoRedoControlsProps> = ({
  undo,
  redo,
  canUndo,
  canRedo,
}) => {
  return (
    <Controls>
      <ControlButton onClick={undo} disabled={!canUndo}>
        <FaUndo />
      </ControlButton>
      <ControlButton onClick={redo} disabled={!canRedo}>
        <FaRedo />
      </ControlButton>
    </Controls>
  );
};

export default UndoRedoControls; 