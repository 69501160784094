import { z } from "zod";

// Step 1: About Campaign Schema
export const aboutCampaignSchema = z.object({
  name: z.string().min(1, "Campaign name is required"),
  description: z.string().min(1, "Campaign description is required"),
});

// Step 2: Scheduling Details Schema
const timeWindowSchema = z
  .object({
    start: z.string().min(1, "Call Window Start time is required"),
    end: z.string().min(1, "Call Window End time is required"),
  })
  .refine((data) => data.start < data.end, {
    message: "Call Window End time must be after Start time",
  });

export const schedulingDetailsSchema = z
  .object({
    startType: z.enum(["immediate", "scheduled"]),
    startDateTime: z.string().optional(),
    endDateTime: z.string().min(1, "Campaign end time is required"),
    maxAttempts: z.number().min(1, "At least one attempt is required").max(10),
    callWindows: z
      .array(timeWindowSchema)
      .min(1, "At least one call window is required"),
    schedulingType: z.enum(["interval", "datetime"]),
    interval: z.number().optional(),
    scheduledDateTimes: z.array(z.string()).optional(),
  })
  .superRefine((data, ctx) => {
    // Validate scheduled start time
    if (data.startType === "scheduled") {
      if (!data.startDateTime) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["startDateTime"],
          message: "Start date & time is required for scheduled campaigns",
        });
      } else {
        const startDate = new Date(data.startDateTime);
        if (startDate < new Date()) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["startDateTime"],
            message: "Start date & time must be in the future",
          });
        }
      }
    }

    // Validate scheduling type specific fields
    if (data.schedulingType === "interval") {
      if (!data.interval || data.interval < 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["interval"],
          message: "Interval must be at least 1 minute for interval scheduling",
        });
      }
    } else if (data.schedulingType === "datetime") {
      if (!data.scheduledDateTimes || data.scheduledDateTimes.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["scheduledDateTimes"],
          message: "At least one scheduled date & time is required",
        });
      } else if (data.scheduledDateTimes.length !== data.maxAttempts) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["scheduledDateTimes"],
          message: "Number of scheduled times must match maximum attempts",
        });
      }

      // Ensure scheduledDateTimes is defined before looping
      if (data.scheduledDateTimes) {
        // Validate that scheduled times are in chronological order
        for (let i = 1; i < data.scheduledDateTimes.length; i++) {
          if (data.scheduledDateTimes[i] <= data.scheduledDateTimes[i - 1]) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["scheduledDateTimes"],
              message: "Scheduled times must be in chronological order",
            });
            break;
          }
        }
      }
    }

    // Add end time validation
    const endDate = new Date(data.endDateTime);
    if (endDate < new Date()) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["endDateTime"],
        message: "End date & time must be in the future",
      });
    }

    // If scheduled, validate that end time is after start time
    if (data.startType === "scheduled" && data.startDateTime) {
      const startDate = new Date(data.startDateTime);
      if (endDate <= startDate) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["endDateTime"],
          message: "End date & time must be after start date & time",
        });
      }
    }
  });

// Step 3: Retries Configuration Schema
export const retriesConfigSchema = z.object({
  limitCallDuration: z.boolean(),
  maxCallDuration: z.number().refine((val) => {
    // If limitCallDuration is false, allow 0
    // If limitCallDuration is true, enforce min 30 and max 3600
    if (!val) return true;
    return val >= 30 && val <= 3600;
  }, "Call duration must be between 30 seconds and 1 hour when limit is enabled"),
  retryTime: z
    .number()
    .min(60, "Retry time must be at least 1 minute")
    .max(86400, "Retry time cannot exceed 24 hours"),
});

// Step 4: Configuration Selection Schema
export const configSelectionSchema = z
  .object({
    pathway: z.string().min(1, "Pathway is required"),
    voiceConfig: z.string().min(1, "Voice configuration is required"),
    successCriteria: z
      .string()
      .min(1, "Success criteria is required")
      .max(3000, "Success criteria cannot exceed 3000 characters"),
    communicationChannel: z.enum(["CALL", "SMS", "WHATSAPP", "EMAIL"], {
      errorMap: () => ({ message: "Valid communication channel is required" }),
    }),
    selectedNumberIds: z
      .array(z.number())
      .min(1, "At least one number must be selected"),
  })
  .superRefine((data, ctx) => {
    if (data.communicationChannel === "CALL") {
      if (data.selectedNumberIds.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["selectedNumberIds"],
          message: "At least one phone number is required for voice campaigns",
        });
      }
    }
  });

// Step 5: Customer Data Schema
export const customerDataSchema = z
  .object({
    dataSource: z.enum(["file", "existing"]),
    customerFile: z.any().optional(),
    uploadedFileUrl: z.string().nullable(),
    selectedCustomers: z
      .array(
        z.object({
          customer_id: z.number(),
          customer_name: z.string(),
          phone_number: z.string(),
          email: z.string(),
          additional_info: z.record(z.any()),
        })
      )
      .optional(),
  })
  .superRefine((data, ctx) => {
    if (data.dataSource === "file") {
      if (!data.customerFile && !data.uploadedFileUrl) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["customerFile"],
          message: "Customer file is required",
        });
      }

      // Validate that file is uploaded when customerFile is present
      if (data.customerFile && !data.uploadedFileUrl) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["uploadedFileUrl"],
          message: "File must be uploaded before creating campaign",
        });
      }
    } else if (data.dataSource === "existing") {
      if (!data.selectedCustomers || data.selectedCustomers.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["selectedCustomers"],
          message: "At least one customer must be selected",
        });
      }
    }
  });

// Complete Campaign Form Schema using `z.intersection`
export const campaignFormSchema = z.intersection(
  z.intersection(
    z.intersection(
      z.intersection(aboutCampaignSchema, schedulingDetailsSchema),
      retriesConfigSchema
    ),
    configSelectionSchema
  ),
  customerDataSchema
);

export type CampaignFormData = z.infer<typeof campaignFormSchema>;
