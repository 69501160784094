import { useDisclosure } from "@chakra-ui/react";
import { WorkflowNodeProps } from "./type";
import NodeView from "./NodeView";

const BaseNode: React.FC<WorkflowNodeProps> = ({
  id,
  selected,
  data,
}: WorkflowNodeProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <NodeView
        id={id}
        data={data}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        selected={selected}
      />
    </>
  );
};

export default BaseNode;
