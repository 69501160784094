import React from "react";
import {
  FormControl,
  FormLabel,
  VStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormErrorMessage,
  HStack,
  Tooltip,
  Icon,
  Switch,
  Box,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";

interface RetriesConfigProps {
  formData: any;
  setFormData: (data: any) => void;
  errors?: Record<string, string>;
}

const RetriesConfig: React.FC<RetriesConfigProps> = ({
  formData,
  setFormData,
  errors = {},
}) => {
  const handleLimitDurationChange = (isChecked: boolean) => {
    setFormData({
      ...formData,
      limitCallDuration: isChecked,
      maxCallDuration: isChecked ? 60 : 0, // Default to 60 seconds when enabled
    });
  };

  const handleMaxDurationChange = (value: string) => {
    setFormData({
      ...formData,
      maxCallDuration: parseInt(value),
    });
  };

  return (
    <VStack spacing={6} align="stretch">
      <FormControl display="flex" alignItems="center">
        <HStack spacing={1}>
          <FormLabel htmlFor="limit-duration" mb="0">
            Limit call duration
          </FormLabel>
          <Tooltip
            label="Enable to set a maximum duration for each call"
            placement="top-start"
          >
            <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
          </Tooltip>
        </HStack>
        <Switch
          id="limit-duration"
          ml="auto"
          isChecked={formData.limitCallDuration}
          onChange={(e) => handleLimitDurationChange(e.target.checked)}
        />
      </FormControl>

      {formData.limitCallDuration && (
        <Box pl={4}>
          <FormControl isRequired isInvalid={!!errors.maxCallDuration}>
            <HStack spacing={1} mb={1}>
              <FormLabel mb={0}>Maximum Call Duration (seconds)</FormLabel>
              <Tooltip
                label="Maximum duration allowed for each call in the campaign"
                placement="top-start"
              >
                <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
              </Tooltip>
            </HStack>
            <NumberInput
              min={30}
              max={3600}
              value={formData.maxCallDuration}
              onChange={handleMaxDurationChange}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormErrorMessage>{errors.maxCallDuration}</FormErrorMessage>
          </FormControl>
        </Box>
      )}

      <FormControl isRequired isInvalid={!!errors.retryTime}>
        <HStack spacing={1} mb={1}>
          <FormLabel mb={0}>Retry Time (seconds)</FormLabel>
          <Tooltip
            label="Time to wait before retrying a failed call attempt"
            placement="top-start"
          >
            <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
          </Tooltip>
        </HStack>
        <NumberInput
          min={60}
          max={86400}
          value={formData.retryTime}
          onChange={(value) =>
            setFormData({ ...formData, retryTime: parseInt(value) })
          }
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <FormErrorMessage>{errors.retryTime}</FormErrorMessage>
      </FormControl>
    </VStack>
  );
};

export default RetriesConfig;
