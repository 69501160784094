import React, { useEffect } from "react";
import {
  FormControl,
  FormLabel,
  VStack,
  Button,
  Input,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { DynamicInputVariable, NodeData } from "../type";

interface FormatTextNodeConfigProps {
  dynamicInputs: DynamicInputVariable[];
  nodeData: NodeData;
  setDynamicInputs: (variables: DynamicInputVariable[]) => void;
  setHiddenInputs: (inputs: Record<string, boolean>) => void;
  setNodeData: (data: NodeData) => void;
}

const FormatTextNodeConfig: React.FC<FormatTextNodeConfigProps> = ({
  dynamicInputs,
  nodeData,
  setDynamicInputs,
  setHiddenInputs,
  setNodeData,
}) => {
  useEffect(() => {
    setHiddenInputs({ template: true });
  }, []);

  return (
    <FormControl>
      <FormLabel>Template</FormLabel>
      <Input
        placeholder="Template"
        value={nodeData.nodeInfo.user_provided_variables?.template || ""}
        onChange={(e) => {
          setNodeData({
            ...nodeData,
            nodeInfo: {
              ...nodeData.nodeInfo,
              user_provided_variables: {
                ...nodeData.nodeInfo.user_provided_variables,
                template: e.target.value,
              },
            },
          });
        }}
      />
      <VStack spacing={4} align="stretch">
        <Button
          leftIcon={<AddIcon />}
          size="sm"
          onClick={() => {
            setDynamicInputs([
              ...dynamicInputs,
              { name: `input${dynamicInputs.length}`, type: "str" },
            ]);
          }}
        >
          Add Variable
        </Button>
      </VStack>
    </FormControl>
  );
};

export default FormatTextNodeConfig;
