import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Select,
  VStack,
  FormErrorMessage,
  Box,
  Text,
  Checkbox,
  CheckboxGroup,
  Spinner,
  Textarea,
  HStack,
  Tooltip,
  Icon,
  Tag,
} from "@chakra-ui/react";
import { getVoiceConfigurations } from "../../../api/voice";
import { getOrganisationAgents } from "../../../api/agents";
import { getAvailableNumbers } from "../../../api/campaigns";
import { VoiceListConfig } from "../../Voices/type";
import { InfoIcon } from "@chakra-ui/icons";

interface ConfigSelectionProps {
  formData: any;
  setFormData: (data: any) => void;
  errors?: Record<string, string>;
}

interface FormattedPhoneNumber {
  id: number;
  number: string;
  label: string;
  sms_enabled: boolean;
  voice_enabled: boolean;
  provider: string;
  status: string;
}

const ConfigSelection: React.FC<ConfigSelectionProps> = ({
  formData,
  setFormData,
  errors = {},
}) => {
  const { organisationId } = { organisationId: "1" };
  const [voices, setVoices] = useState<VoiceListConfig[]>([]);
  const [agents, setAgents] = useState<any[]>([]);
  const [phoneNumbers, setPhoneNumbers] = useState<FormattedPhoneNumber[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [voicesResponse, agentsResponse] = await Promise.all([
          getVoiceConfigurations(),
          getOrganisationAgents(organisationId),
        ]);
        setVoices(voicesResponse);
        setAgents(agentsResponse);

        // Fetch available numbers with new endpoint
        const numbersResponse = await getAvailableNumbers();

        // Format the phone numbers using the new structure
        const formattedNumbers = numbersResponse.data.map((number) => ({
          id: number.number_id,
          number: number.number,
          label: `Phone Line ${number.number_id}`,
          sms_enabled: number.sms_enabled,
          voice_enabled: number.voice_enabled,
          provider: number.provider,
          status: number.status,
        }));
        setPhoneNumbers(formattedNumbers);
      } catch (error) {
        console.error("Error fetching configurations:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [organisationId]);

  const handleNumberSelection = (selectedNumbers: string[]) => {
    const selectedIds = phoneNumbers
      .filter((phone) => selectedNumbers.includes(phone.number))
      .map((phone) => phone.id);

    setFormData({
      ...formData,
      selectedNumberIds: selectedIds,
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <VStack spacing={6} align="stretch">
      <FormControl isRequired isInvalid={!!errors.pathway}>
        <HStack spacing={1} mb={1}>
          <FormLabel mb={0}>Pathway</FormLabel>
          <Tooltip
            label="Select the conversation flow for your campaign"
            placement="top-start"
          >
            <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
          </Tooltip>
        </HStack>
        <Select
          placeholder="Select pathway"
          value={formData.pathway}
          onChange={(e) =>
            setFormData({ ...formData, pathway: e.target.value })
          }
        >
          {agents.map((agent) => (
            <option key={agent.agentId} value={agent.agentId}>
              {agent.agentName || agent.agentId}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{errors.pathway}</FormErrorMessage>
      </FormControl>

      <FormControl isRequired isInvalid={!!errors.voiceConfig}>
        <HStack spacing={1} mb={1}>
          <FormLabel mb={0}>Voice Configuration</FormLabel>
          <Tooltip
            label="Choose the voice settings for your campaign calls"
            placement="top-start"
          >
            <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
          </Tooltip>
        </HStack>
        <Select
          placeholder="Select voice configuration"
          value={formData.voiceConfig}
          onChange={(e) =>
            setFormData({ ...formData, voiceConfig: e.target.value })
          }
        >
          {voices.map((voice) => (
            <option key={voice.agent_id} value={voice.agent_id}>
              {voice.agent_name || voice.agent_id}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{errors.voiceConfig}</FormErrorMessage>
      </FormControl>

      <FormControl isRequired isInvalid={!!errors.communicationChannel}>
        <HStack spacing={1} mb={1}>
          <FormLabel mb={0}>Communication Channel</FormLabel>
          <Tooltip
            label="Select how you want to communicate with your customers"
            placement="top-start"
          >
            <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
          </Tooltip>
        </HStack>
        <Select
          placeholder="Select communication channel"
          value={formData.communicationChannel}
          onChange={(e) =>
            setFormData({ ...formData, communicationChannel: e.target.value })
          }
        >
          <option value="CALL">CALL</option>
          <option value="SMS">SMS</option>
          <option value="WHATSAPP">WHATSAPP</option>
          <option value="EMAIL">EMAIL</option>
        </Select>
        <FormErrorMessage>{errors.communicationChannel}</FormErrorMessage>
      </FormControl>

      <FormControl isRequired isInvalid={!!errors.selectedNumbers}>
        <HStack spacing={1} mb={1}>
          <FormLabel mb={0}>Select Phone Numbers</FormLabel>
          <Tooltip
            label="Choose the phone numbers to use for this campaign"
            placement="top-start"
          >
            <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
          </Tooltip>
        </HStack>
        <Box
          maxH="200px"
          overflowY="auto"
          borderWidth={1}
          borderRadius="md"
          p={2}
        >
          <CheckboxGroup
            value={phoneNumbers
              .filter((phone) => formData.selectedNumberIds.includes(phone.id))
              .map((phone) => phone.number)}
            onChange={handleNumberSelection}
          >
            <VStack align="stretch" spacing={2}>
              {phoneNumbers.map((phone) => (
                <Checkbox
                  key={phone.id}
                  value={phone.number}
                  isDisabled={!phone.voice_enabled || phone.status !== "FREE"}
                >
                  <HStack spacing={2}>
                    <Text fontSize="sm">{phone.number}</Text>
                    <Tag
                      size="sm"
                      colorScheme={phone.status === "FREE" ? "green" : "red"}
                    >
                      {phone.status}
                    </Tag>
                    {phone.voice_enabled && (
                      <Tag size="sm" colorScheme="blue">
                        Voice
                      </Tag>
                    )}
                    {phone.sms_enabled && (
                      <Tag size="sm" colorScheme="purple">
                        SMS
                      </Tag>
                    )}
                  </HStack>
                </Checkbox>
              ))}
            </VStack>
          </CheckboxGroup>
        </Box>
        <FormErrorMessage>{errors.selectedNumbers}</FormErrorMessage>
      </FormControl>

      <FormControl isRequired isInvalid={!!errors.successCriteria}>
        <HStack spacing={1} mb={1}>
          <FormLabel mb={0}>Conversion Criteria</FormLabel>
          <Tooltip
            label="Defines when a customer is considered interested for this campaign"
            placement="top-start"
          >
            <Icon as={InfoIcon} color="blue.500" w={3.5} h={3.5} />
          </Tooltip>
        </HStack>
        <Textarea
          placeholder="Enter the conversion criteria for a customer"
          value={formData.successCriteria}
          onChange={(e) =>
            setFormData({ ...formData, successCriteria: e.target.value })
          }
          maxLength={3000}
          rows={4}
        />
        <FormErrorMessage>{errors.successCriteria}</FormErrorMessage>
      </FormControl>
    </VStack>
  );
};

export default ConfigSelection;
