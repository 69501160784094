import React from "react";
import {
  Flex,
  IconButton,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { FiBell, FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

type Notification = {
  id: string;
  message: string;
};

const GradientIcon: React.FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill="url(#gradient)" />
    <defs>
      <linearGradient
        id="gradient"
        x1="0"
        y1="0"
        x2="32"
        y2="32"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFB3BA" offset="0%" />
        <stop stopColor="#BFACE2" offset="50%" />
        <stop stopColor="#FFC8DD" offset="100%" />
      </linearGradient>
    </defs>
  </svg>
);

const TopNav: React.FC = () => {
  const navigate = useNavigate();
  const notifications: Notification[] = [];

  const handleSignOut = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <Flex
      as="nav"
      align="center"
      justify="flex-end"
      h="56px"
      px={3}
      bg="white"
      boxShadow="sm"
      width="100%"
      position="sticky"
      top={0}
      zIndex={1}
    >
      <Flex align="center">
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Notifications"
            icon={<FiBell />}
            mr={4}
          />
          <MenuList>
            {notifications.length === 0 ? (
              <MenuItem>No notifications</MenuItem>
            ) : (
              notifications.map((notification) => (
                <MenuItem key={notification.id}>
                  {notification.message}
                </MenuItem>
              ))
            )}
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton as={Box} cursor="pointer">
            <GradientIcon />
          </MenuButton>
          <MenuList>
            <MenuItem icon={<FiLogOut />} onClick={handleSignOut}>
              Sign Out
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default TopNav;
