import React, { useEffect } from "react";
import {
  FormControl,
  FormLabel,
  VStack,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  IconButton,
  Portal,
} from "@chakra-ui/react";
import { ChevronDownIcon, DeleteIcon, AddIcon } from "@chakra-ui/icons";
import { ExtractedVariable } from "../type";

interface ExtractVariablesConfigProps {
  extractedVariables: ExtractedVariable[];
  setExtractedVariables: (extractedVariables: ExtractedVariable[]) => void;
  setHiddenInputs: (inputs: Record<string, boolean>) => void;
}

const ExtractVariablesConfig: React.FC<ExtractVariablesConfigProps> = ({
  extractedVariables,
  setExtractedVariables,
  setHiddenInputs,
}) => {
  useEffect(() => {
    setHiddenInputs({ variable_schema: true });
  }, []);

  return (
    <FormControl>
      <FormLabel>Variables</FormLabel>
      <VStack spacing={4} align="stretch">
        {extractedVariables.map((variable, index) => (
          <HStack key={index} spacing={2} align="start">
            <VStack flex={1} spacing={2}>
              <Input
                placeholder="Variable name"
                value={variable.name}
                onChange={(e) => {
                  const newVariables = [...extractedVariables];
                  newVariables[index] = {
                    ...variable,
                    name: e.target.value,
                  };
                  setExtractedVariables(newVariables);
                }}
              />
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  width="full"
                  textAlign="left"
                  variant="outline"
                >
                  {variable.type || "Select type"}
                </MenuButton>
                <Portal>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        const newVariables = [...extractedVariables];
                        newVariables[index] = {
                          ...variable,
                          type: "string",
                        };
                        setExtractedVariables(newVariables);
                      }}
                    >
                      String
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        const newVariables = [...extractedVariables];
                        newVariables[index] = {
                          ...variable,
                          type: "number",
                        };
                        setExtractedVariables(newVariables);
                      }}
                    >
                      Number
                    </MenuItem>
                  </MenuList>
                </Portal>
              </Menu>
            </VStack>

            <IconButton
              aria-label="Remove variable"
              icon={<DeleteIcon />}
              colorScheme="red"
              variant="ghost"
              size="sm"
              isDisabled={extractedVariables.length === 1}
              onClick={() => {
                const newVariables = extractedVariables.filter(
                  (_, i) => i !== index
                );
                setExtractedVariables(newVariables);
              }}
            />
          </HStack>
        ))}

        <Button
          leftIcon={<AddIcon />}
          size="sm"
          onClick={() => {
            setExtractedVariables([
              ...extractedVariables,
              { name: "", type: "string" },
            ]);
          }}
        >
          Add Variable
        </Button>
      </VStack>
    </FormControl>
  );
};

export default ExtractVariablesConfig;
