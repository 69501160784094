import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Icon,
  useToast,
  HStack,
  VStack,
  Box,
  Spinner,
  Progress,
} from "@chakra-ui/react";
import { FiUpload, FiCheck, FiFile, FiX } from "react-icons/fi";
import { NodeData } from "../type";
import {
  getPresignedUrls,
  processPdfUpload,
  uploadFileToS3,
} from "../../../../../api/workflows";
import { useDocumentProcessing } from "../../../hooks/useDocumentProcessing";
interface DocumentReaderNodeConfigProps {
  nodeData: NodeData;
  setNodeData: (nodeData: NodeData) => void;
  setHiddenInputs: (inputs: Record<string, boolean>) => void;
}

const DocumentReaderNodeConfig: React.FC<DocumentReaderNodeConfigProps> = ({
  nodeData,
  setNodeData,
  setHiddenInputs,
}) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [key, setKey] = useState<string | null>(null);
  const toast = useToast();
  const isPdf = nodeData.nodeInfo.name === "pdf_reader";
  const [isUploading, setIsUploading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingUrl, setProcessingUrl] = useState<string | null>(null);
  const { status, error } = useDocumentProcessing("1", processingUrl);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    setHiddenInputs({ s3_url: true });
    const existingS3Url = nodeData.nodeInfo.user_provided_variables.s3_url;
    if (existingS3Url) {
      const keyFromUrl = existingS3Url.split("/").pop();
      setKey(keyFromUrl || null);
    }
  }, []);

  useEffect(() => {
    if (status === "completed") {
      setIsProcessing(false);
      toast({
        title: "PDF processing completed",
        status: "success",
        duration: 3000,
      });
      setProcessingUrl(null);
    } else if (status === "in_progress") {
      setIsProcessing(true);
    } else if (error) {
      setIsProcessing(false);
      toast({
        title: "PDF processing failed",
        description: error.message,
        status: "error",
        duration: 5000,
      });
      setProcessingUrl(null);
    }
  }, [status, error]);

  const handlePdfProcessing = async (s3Url: string) => {
    setIsProcessing(true);
    try {
      await processPdfUpload("1", [s3Url]);
      setProcessingUrl(s3Url); // Start polling
    } catch (error: any) {
      setIsProcessing(false);
      toast({
        title: "Failed to start PDF processing",
        description: error.message,
        status: "error",
        duration: 5000,
      });
    }
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setIsUploading(true);
      try {
        const presignedUrls = await getPresignedUrls("1", [
          { name: file.name, type: file.type },
        ]);
        const s3Url = presignedUrls[0].s3Uri;
        await uploadFileToS3(presignedUrls[0].uploadUrl, file);
        setKey(presignedUrls[0].key);
        setNodeData({
          ...nodeData,
          nodeInfo: {
            ...nodeData.nodeInfo,
            user_provided_variables: {
              ...nodeData.nodeInfo.user_provided_variables,
              s3_url: s3Url,
            },
          },
        });

        if (isPdf) {
          handlePdfProcessing(s3Url).catch(() => {});
        } else {
          toast({
            title: "File uploaded successfully",
            status: "success",
            duration: 3000,
          });
        }
      } catch (err: any) {
        toast({
          title: "Error uploading file",
          description: err.message,
          status: "error",
        });
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleRemoveFile = () => {
    setKey(null);
    setNodeData({
      ...nodeData,
      nodeInfo: {
        ...nodeData.nodeInfo,
        user_provided_variables: {
          ...nodeData.nodeInfo.user_provided_variables,
          s3_url: "",
        },
      },
    });
  };

  return (
    <FormControl>
      <FormLabel>Upload File</FormLabel>
      <Input
        type="file"
        accept={isPdf ? ".pdf" : ".csv"}
        onChange={handleFileUpload}
        display="none"
        ref={fileInputRef}
      />
      {!key ? (
        <Button
          onClick={handleButtonClick}
          leftIcon={<Icon as={FiUpload} />}
          colorScheme="blue"
          variant="outline"
          width="full"
          height="40px"
          _hover={{ bg: "blue.50" }}
          isLoading={isUploading || (isPdf && isProcessing)}
          loadingText={isUploading ? "Uploading..." : "Processing PDF..."}
        >
          Choose File
        </Button>
      ) : (
        <Box
          borderWidth="1px"
          borderRadius="md"
          p={2}
          borderColor={isPdf && isProcessing ? "yellow.200" : "blue.200"}
          bg={isPdf && isProcessing ? "yellow.50" : "blue.50"}
          width="100%"
          position="relative"
        >
          <VStack spacing={2} align="stretch">
            <HStack spacing={2}>
              {isPdf && isProcessing ? (
                <Spinner size="sm" color="yellow.500" />
              ) : (
                <Icon as={FiCheck} color="green.500" boxSize={4} />
              )}
              <Icon as={FiFile} color="blue.500" boxSize={4} />
              <Text
                fontWeight="medium"
                color="gray.700"
                fontSize="sm"
                noOfLines={1}
              >
                {key}
              </Text>
            </HStack>

            {isPdf && isProcessing && (
              <Progress
                size="xs"
                isIndeterminate
                colorScheme="yellow"
                borderRadius="full"
              />
            )}

            <HStack spacing={2} justify="flex-end">
              <Button
                size="xs"
                leftIcon={<Icon as={FiUpload} boxSize={3} />}
                onClick={handleButtonClick}
                colorScheme="blue"
                variant="ghost"
                isDisabled={isPdf && isProcessing}
              >
                Replace
              </Button>
              <Button
                size="xs"
                leftIcon={<Icon as={FiX} boxSize={3} />}
                onClick={handleRemoveFile}
                colorScheme="red"
                variant="ghost"
                isDisabled={isPdf && isProcessing}
              >
                Remove
              </Button>
            </HStack>
          </VStack>
        </Box>
      )}
    </FormControl>
  );
};

export default DocumentReaderNodeConfig;
