import React, { useState } from "react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Text,
  VStack,
  HStack,
  IconButton,
  Divider,
  Icon,
} from "@chakra-ui/react";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { getCategoryColor } from "../../helpers/WorkflowBuilder.helper";
import { ToolsMenuProps } from "../../interfaces/interfaces";
import { availableNodeTypes } from "../WorkflowNode/NodeTypes";

const ToolsMenu: React.FC<ToolsMenuProps> = ({
  tools,
  onToolSelect,
  isOpen,
  onClose,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const getNodeInfo = (nodeType: string) => {
    const node = availableNodeTypes.find((node) => node.type === nodeType);
    return {
      icon: node?.icon,
      name: node?.name,
    };
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          {selectedCategory ? (
            <HStack spacing={4}>
              <IconButton
                aria-label="Back"
                icon={<ChevronLeftIcon />}
                onClick={() => setSelectedCategory(null)}
                size="sm"
              />
              <Text
                fontWeight="bold"
                color={`${getCategoryColor(selectedCategory)}.500`}
              >
                {getNodeInfo(selectedCategory)?.name || selectedCategory}
              </Text>
            </HStack>
          ) : (
            <Text>Node Library</Text>
          )}
        </DrawerHeader>

        <Divider mb={4} />

        <DrawerBody>
          {selectedCategory ? (
            <VStack spacing={3} align="stretch">
              {tools[selectedCategory].tools.map((tool) => (
                <Button
                  key={tool.name}
                  onClick={() => {
                    onToolSelect({ ...tool, category: selectedCategory });
                    onClose();
                  }}
                  height="auto"
                  py={4}
                  borderRadius="xl"
                  colorScheme={getCategoryColor(selectedCategory)}
                  _hover={{ opacity: 0.8 }}
                >
                  <VStack spacing={1} w="100%" align="start">
                    <HStack w="100%" justify="space-between">
                      <Text fontSize="sm" fontWeight="bold">
                        {tool.category !== "BaseTool"
                          ? tool.name
                              .split("_")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")
                          : tool.name}
                      </Text>
                    </HStack>
                    <Text
                      fontSize="xs"
                      textAlign="left"
                      wordBreak="break-word"
                      whiteSpace="normal"
                    >
                      {tool.description}
                    </Text>
                  </VStack>
                </Button>
              ))}
            </VStack>
          ) : (
            <VStack spacing={3} align="stretch">
              {Object.entries(tools).map(([categoryName, category]) => {
                const nodeInfo = getNodeInfo(categoryName);
                return (
                  <Button
                    key={categoryName}
                    onClick={() => setSelectedCategory(categoryName)}
                    height="auto"
                    py={4}
                    borderRadius="xl"
                    colorScheme={getCategoryColor(categoryName)}
                    _hover={{ opacity: 0.8 }}
                  >
                    <VStack spacing={1} w="100%" align="start">
                      <HStack w="100%" justify="space-between">
                        <Text fontSize="sm" fontWeight="bold">
                          {nodeInfo?.name || categoryName}
                        </Text>
                        {nodeInfo?.icon && (
                          <Icon as={nodeInfo.icon} boxSize={5} />
                        )}
                      </HStack>
                      <Text
                        fontSize="xs"
                        textAlign="left"
                        wordBreak="break-word"
                        whiteSpace="normal"
                      >
                        {category.description}
                      </Text>
                    </VStack>
                  </Button>
                );
              })}
            </VStack>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ToolsMenu;
