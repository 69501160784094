import React from 'react';
import { FormControl, FormLabel, Input, Tooltip } from '@chakra-ui/react';
import { NodeData } from '../type';

interface DefaultNodeConfigProps {
  nodeData: NodeData;
  setNodeData: (data: NodeData) => void;
  hiddenInputs: Record<string, boolean>;
}

const DefaultNodeConfig: React.FC<DefaultNodeConfigProps> = ({
  nodeData,
  setNodeData,
  hiddenInputs,
}) => {
  return (
    <>
      {nodeData.nodeInfo.schema.inputs &&
        Object.entries(nodeData.nodeInfo.schema.inputs).map(([name, _]) => (
          <FormControl key={name}>
            <FormLabel>{name}</FormLabel>
            <Tooltip
              isDisabled={hiddenInputs[name]}
              label="This input is connected via an incoming edge"
              placement="top"
            >
              <Input
                type="text"
                value={nodeData.nodeInfo.user_provided_variables?.[name] || ""}
                onChange={(e) => {
                  setNodeData({
                    ...nodeData,
                    nodeInfo: {
                      ...nodeData.nodeInfo,
                      user_provided_variables: {
                        ...nodeData.nodeInfo.user_provided_variables,
                        [name]: e.target.value,
                      },
                    },
                  });
                }}
                isDisabled={!hiddenInputs[name]}
                _disabled={{
                  opacity: 0.7,
                  cursor: "not-allowed",
                }}
              />
            </Tooltip>
          </FormControl>
        ))}
    </>
  );
};

export default DefaultNodeConfig; 