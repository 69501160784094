import React from 'react';
import {
  FormControl,
  FormLabel,
  VStack,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  IconButton,
  Portal,
} from '@chakra-ui/react';
import { ChevronDownIcon, DeleteIcon, AddIcon } from '@chakra-ui/icons';
import { ConditionGroup, NodeData } from '../type';

interface ConditionalNodeConfigProps {
  nodeData: NodeData;
  conditionGroup: ConditionGroup;
  setConditionGroup: (group: ConditionGroup) => void;
  conditions: Record<string, string>;
}

const ConditionalNodeConfig: React.FC<ConditionalNodeConfigProps> = ({
  nodeData,
  conditionGroup,
  setConditionGroup,
  conditions,
}) => {
  return (
    <FormControl>
      <FormLabel>Conditions</FormLabel>
      <VStack spacing={4} align="stretch">
        {conditionGroup.conditions.map((condition, index) => (
          <HStack key={index} spacing={2} align="start">
            <VStack flex={1} spacing={2}>
              {/* Input Selection Menu */}
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  width="full"
                  textAlign="left"
                  variant="outline"
                >
                  {condition.input || "Select input"}
                </MenuButton>
                <Portal>
                  <MenuList>
                    {Object.keys(nodeData.nodeInfo.schema.inputs || {}).map((input) => (
                      <MenuItem
                        key={input}
                        onClick={() => {
                          const newConditions = [...conditionGroup.conditions];
                          newConditions[index] = {
                            ...condition,
                            input: input,
                          };
                          setConditionGroup({
                            ...conditionGroup,
                            conditions: newConditions,
                          });
                        }}
                      >
                        {input}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Portal>
              </Menu>

              {/* Operator Selection Menu */}
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  width="full"
                  textAlign="left"
                  variant="outline"
                >
                  {conditions[condition.operator] || "Select operator"}
                </MenuButton>
                <Portal>
                  <MenuList>
                    {Object.entries(conditions).map(([key, value]) => (
                      <MenuItem
                        key={key}
                        onClick={() => {
                          const newConditions = [...conditionGroup.conditions];
                          newConditions[index] = {
                            ...condition,
                            operator: key,
                          };
                          setConditionGroup({
                            ...conditionGroup,
                            conditions: newConditions,
                          });
                        }}
                      >
                        {value}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Portal>
              </Menu>

              {/* Value Input */}
              <Input
                placeholder="Enter value"
                value={condition.value}
                onChange={(e) => {
                  const newConditions = [...conditionGroup.conditions];
                  newConditions[index] = {
                    ...condition,
                    value: e.target.value,
                  };
                  setConditionGroup({
                    ...conditionGroup,
                    conditions: newConditions,
                  });
                }}
              />
            </VStack>

            <IconButton
              aria-label="Remove condition"
              icon={<DeleteIcon />}
              colorScheme="red"
              variant="ghost"
              size="sm"
              isDisabled={conditionGroup.conditions.length === 1}
              onClick={() => {
                const newConditions = conditionGroup.conditions.filter(
                  (_, i) => i !== index
                );
                setConditionGroup({
                  ...conditionGroup,
                  conditions: newConditions,
                });
              }}
            />
          </HStack>
        ))}

        {/* Logical Operator Selection */}
        {conditionGroup.conditions.length > 1 && (
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              width="full"
              variant="outline"
            >
              {conditionGroup.logicalOperator}
            </MenuButton>
            <Portal>
              <MenuList>
                <MenuItem
                  onClick={() =>
                    setConditionGroup({
                      ...conditionGroup,
                      logicalOperator: "AND",
                    })
                  }
                >
                  AND
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    setConditionGroup({
                      ...conditionGroup,
                      logicalOperator: "OR",
                    })
                  }
                >
                  OR
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        )}

        {/* Add Condition Button */}
        <Button
          leftIcon={<AddIcon />}
          size="sm"
          onClick={() => {
            setConditionGroup({
              ...conditionGroup,
              conditions: [
                ...conditionGroup.conditions,
                { input: "", operator: "", value: "" },
              ],
            });
          }}
        >
          Add Condition
        </Button>
      </VStack>
    </FormControl>
  );
};

export default ConditionalNodeConfig; 